import React from 'react';
import { FC } from 'react';

import { Box, Divider } from '@mui/material';
import { AddAssetRunningEditor } from './AddAssetRunningEditor';
import { AddAssetDayRunningEditor } from './AddAssetBusinessRunningEditor';
import { AssetRunnings, AssetBusinessHours } from 'src/apis/type';

export interface AssetRunningContainerProps {
    initValue?: AssetRunnings;
    onChange?: (runnings: AssetRunnings) => void;
    onRunningHourChange?: (bsRunnings: AssetBusinessHours) => void;
}

const AssetRunningContainer: FC<AssetRunningContainerProps> = ({ onChange, onRunningHourChange, initValue }) => {
    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" overflow="scroll">
            <AddAssetRunningEditor onChange={onChange} intiValue={initValue} />
        </Box>
    );
};

export default AssetRunningContainer;
