import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { UpdateEventEditor } from './UpdateEventEditor';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EventUpdateContainer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Box
            width="100%"
            height="calc(100svh - 80px)"
            display={'flex'}
            sx={{
                scrollbarWidth: 'none', // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none' // Hide the scrollbar for IE
                }
            }}
        >
            <Box width="100%" height="100%" display="flex" flexDirection="column" overflow="scroll">
                <Box width="100%" display="flex" alignItems="center" mt={1} mb={1}>
                    <Box>
                        <IconButton
                            onClick={() => {
                                navigate('/event');
                            }}
                        >
                            <ArrowBackIcon></ArrowBackIcon>
                        </IconButton>
                    </Box>
                    <Box ml={1}>
                        <Typography fontSize="18px" fontWeight={700} color="black">
                            {`${t('names:updateEvent')}:`}
                        </Typography>
                    </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                    <UpdateEventEditor />
                </Box>
            </Box>
        </Box>
    );
};

export default EventUpdateContainer;
