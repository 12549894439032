import * as React from 'react';
import { FC, useRef, useState } from 'react';
import { Box, TextField, Typography, Button, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { Asset } from 'src/apis/type';
import { useRegisterAssetMutationQuery } from 'src/apis/hook/asset';
import { useAuthenticated } from 'src/apis/hook/user';
import { useSimpleAlert } from 'src/store/ui';
import { useNavigate } from 'react-router-dom';
import { useAssetsUI } from 'src/store/ui';
import { colors } from 'src/theme/colors';

export const AssetDetail: FC = () => {
    const { t } = useTranslation();
    const { selectedAsset } = useAssetsUI();
    return (
        <Box width="100%" height="100%" alignItems="center" display="flex" flexDirection="column">
            {selectedAsset && (
                <Box
                    mt={2}
                    width="500px"
                    height="300px"
                    sx={{ background: colors.main.gray00, borderRadius: 1 }}
                    p={1}
                    display="flex"
                >
                    <Box width="220px">
                        <img
                            src={selectedAsset.backgroundImage}
                            alt="image"
                            style={{ width: '100%', height: '100%' }}
                        />
                    </Box>
                    <Box ml={1} display="flex" flexDirection="column" width="275px">
                        <Box>
                            <Typography fontSize="24px" fontWeight={700} color="black">
                                {selectedAsset.name}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography fontSize="14px" fontWeight={400} color="black">
                                {selectedAsset.desc}
                            </Typography>
                        </Box>
                        {selectedAsset.howToCome && selectedAsset.howToCome.length > 0 && (
                            <Box>
                                <Typography fontSize="14px" fontWeight={400} color="black" mt={0.5}>
                                    {selectedAsset.howToCome}
                                </Typography>
                            </Box>
                        )}
                        <Box width="100%" overflow="hidden" display="flex" mt={0.5}>
                            <Typography fontSize="14px" fontWeight={400} color="black">
                                {`[${t('names:address')}]`}
                                {`${selectedAsset.address} ${selectedAsset.addressDetail}`}
                            </Typography>
                        </Box>
                        {selectedAsset.moreInfo && selectedAsset.moreInfo.length > 0 && (
                            <Box>
                                <Typography
                                    whiteSpace="break-spaces"
                                    fontSize="14px"
                                    fontWeight={400}
                                    color="black"
                                    mt={0.5}
                                >
                                    {selectedAsset.moreInfo}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};
