import * as React from 'react';
import { FC, useRef, useState } from 'react';
import { Box, TextField, Typography, Button, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { Archive } from 'src/apis/type';
import { useRegisterArchiveMutationQuery } from 'src/apis/hook/archive';
import { useAuthenticated } from 'src/apis/hook/user';
import { useSimpleAlert } from 'src/store/ui';
import { useNavigate } from 'react-router-dom';

export const AddArchiveEditor: FC = () => {
    const { mutateAsync: registerArchive } = useRegisterArchiveMutationQuery();
    const { data: authenticated } = useAuthenticated();
    const { alert: simpleAlert } = useSimpleAlert();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');

    const hiddenMainFileInputRef = useRef(null);

    const [mainImageFile, setMainImageFiles] = useState<any>();
    const [mainImageUrl, setMainImageUrl] = useState<string>();

    const handleMainFileAttachClick = (event: any) => {
        if (event.target.files.length > 0) {
            const reader = new FileReader();
            const file = event.target.files[0];
            setMainImageFiles(file);
            reader.onloadend = (e) => {
                if (e.target) {
                    setMainImageUrl(e.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const resetPage = () => {
        setTitle('');
        setLink('');
        setMainImageFiles(undefined);
        setMainImageUrl('');
    };

    const updateValue = async (value: string, mode: string) => {
        if (mode === 'title') {
            setTitle(value);
        } else if (mode === 'link') {
            setLink(value);
        }
    };

    const tryRegisterAsset = async () => {
        if (authenticated) {
            if (title && title.length > 0 && link) {
                const formData = new FormData();
                if (mainImageFile) {
                    formData.append('imageFiles', mainImageFile, mainImageFile.name);
                }
                const assetInfo: Archive = {
                    title: title,
                    link: link
                };
                formData.append('asset', JSON.stringify(assetInfo));
                const result = await registerArchive(formData);
                if (result) {
                    simpleAlert(t('names:notification'), t('names:successRegisterArchive'), 'info');
                    resetPage();
                }
            } else {
                simpleAlert(t('names:error'), t('names:missingEssential'), 'error');
            }
        } else {
            simpleAlert(t('names:error'), t('names:logoutStatus'), 'error');
            navigate('/login');
        }
    };

    return (
        <Box width="100%" alignItems="center" display="flex" flexDirection="column" maxWidth="800px">
            <Box mt={1}>
                <Typography fontSize="24px" fontWeight={700} color="black">
                    {t('names:addArchive')}
                </Typography>
            </Box>
            <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }}>
                <Grid item xs={12}>
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:title')}
                        variant="outlined"
                        size="small"
                        value={title}
                        onChange={(e) => updateValue(e.target.value, 'title')}
                    />
                </Grid>
            </Grid>
            <Box p={1} width="100%">
                <TextField
                    id="outlined-basic"
                    label={t('names:link')}
                    variant="outlined"
                    size="small"
                    value={link}
                    sx={{ width: '100%' }}
                    onChange={(e) => updateValue(e.target.value, 'link')}
                />
            </Box>
            <Box mt={2} width="100%" p={1}>
                <Button
                    color="inherit"
                    size="small"
                    sx={{ border: 'solid', borderColor: '#CCCEDD', marginRight: '11px' }}
                    onClick={() => {
                        if (hiddenMainFileInputRef && hiddenMainFileInputRef.current) {
                            const fileInput = hiddenMainFileInputRef.current as HTMLInputElement;
                            fileInput.click();
                        }
                    }}
                    startIcon={<AttachFileIcon fontSize="small" color="primary" />}
                >
                    <Typography fontSize="14px" color="black">
                        {t('names:mainImageFile')}
                    </Typography>
                </Button>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={hiddenMainFileInputRef}
                    onChange={handleMainFileAttachClick}
                    accept="image/*"
                />
            </Box>
            <Box width="100%" p={1} display="flex">
                <Box
                    width="150px"
                    height="150px"
                    display="flex"
                    justifyContent="right"
                    sx={{
                        border: 'solid 1px #CCCEDD',
                        backgroundImage: mainImageUrl && mainImageUrl.length > 0 ? `url(${mainImageUrl})` : '',
                        backgroundSize: 'cover'
                    }}
                ></Box>
            </Box>
            <Box width="100%" p={2} display="flex" justifyContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        tryRegisterAsset();
                    }}
                >
                    {t('names:doRegister')}
                </Button>
            </Box>
        </Box>
    );
};
