import * as React from 'react';
import { FC, useRef, useState } from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    Grid,
    IconButton,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { Asset, AssetRunnings, AssetBusinessHours } from 'src/apis/type';
import { useRegisterAssetMutationQuery } from 'src/apis/hook/asset';
import { useAuthenticated } from 'src/apis/hook/user';
import { useSimpleAlert } from 'src/store/ui';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

export const AddAssetEditor: FC = () => {
    const open = useDaumPostcodePopup('https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js');
    const { mutateAsync: registerAsset } = useRegisterAssetMutationQuery();
    const { data: authenticated } = useAuthenticated();
    const { alert: simpleAlert } = useSimpleAlert();
    const navigate = useNavigate();

    const handleClick = () => {
        open({ onComplete: handleComplete });
    };
    const handleComplete = (data: any) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setAddress(fullAddress);
    };

    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [typeName, setTypeName] = useState('');
    const [desc, setDesc] = useState('');
    const [type, setType] = useState('space');
    const [minCapa, setMinCapa] = useState<number | undefined>(1);
    const [maxCapa, setMaxCapa] = useState<number | undefined>(1);
    const [perDay, setPerDay] = useState<number | undefined>(1);
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [personInCharge, setPersonInCharge] = useState('');
    const [howToCome, setHowToCome] = useState<string>();
    const [moreInfo, setMoreInfo] = useState<string>();
    //const [assetRunnings, setAssetRunnings] = useState<AssetRunnings>([]);
    //const [assetBHRunnings, setAssetBHRunnings] = useState<AssetBusinessHours>([]);

    const hiddenFileInputRef = useRef(null);
    const hiddenMainFileInputRef = useRef(null);

    const [imageFiles, setImageFiles] = useState<any[]>([]);
    const [imageUrls, setImageUrls] = useState<string[]>([]);

    const [mainImageFile, setMainImageFiles] = useState<any>();
    const [mainImageUrl, setMainImageUrl] = useState<string>();

    const [timeGap, setTimeGap] = useState<number | undefined>(30);

    const handleFileAttachClick = (event: any) => {
        if (event.target.files.length > 0) {
            const reader = new FileReader();
            const file = event.target.files[0];
            setImageFiles([...imageFiles, file]);
            reader.onloadend = (e) => {
                if (e.target) {
                    setImageUrls([...imageUrls, e.target.result as string]);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleMainFileAttachClick = (event: any) => {
        if (event.target.files.length > 0) {
            const reader = new FileReader();
            const file = event.target.files[0];
            setMainImageFiles(file);
            reader.onloadend = (e) => {
                if (e.target) {
                    setMainImageUrl(e.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const removeImage = (index: number) => {
        const newImageFiles = imageFiles.filter((_, i) => i !== index);
        const newImageUrls = imageUrls.filter((_, i) => i !== index);
        setImageFiles(newImageFiles);
        setImageUrls(newImageUrls);
    };

    const resetPage = () => {
        setName('');
        setDesc('');
        setType('');
        setAddress('');
        setAddressDetail('');
        setPersonInCharge('');
        setHowToCome('');
        setMoreInfo('');
        setImageFiles([]);
        setImageUrls([]);
        setMainImageFiles(undefined);
        setMainImageUrl('');
        setTimeGap(30);
    };

    const updateValue = async (value: string, mode: string) => {
        if (mode === 'name') {
            setName(value);
        } else if (mode === 'desc') {
            setDesc(value);
        } else if (mode === 'type') {
            setType(value);
        } else if (mode === 'addressDetail') {
            setAddressDetail(value);
        } else if (mode === 'personInCharge') {
            setPersonInCharge(value);
        } else if (mode === 'howToCome') {
            setHowToCome(value);
        } else if (mode === 'moreInfo') {
            setMoreInfo(value);
        } else if (mode === 'typeName') {
            setTypeName(value);
        }
    };

    const tryRegisterAsset = async () => {
        if (authenticated) {
            if (name && name.length > 0 && address && mainImageFile) {
                const formData = new FormData();

                imageFiles.forEach((item, index) => {
                    formData.append('detailImageFiles', item, `${index}-${item.name}`);
                });
                if (mainImageFile) {
                    formData.append('backgroundFile', mainImageFile, mainImageFile.name);
                }
                /*const adaptedRunning = assetRunnings.map((item, index) => {
                    return { ...item, endTime: item.endTime == 0 ? 24 * 60 : item.endTime };
                });*/
                const assetInfo: Asset = {
                    name: name,
                    desc: desc,
                    type: type,
                    typeName: typeName,
                    address: address,
                    addressDetail: addressDetail,
                    inCharge: personInCharge,
                    moreInfo: moreInfo,
                    howToCome: howToCome,
                    //runningDays: adaptedRunning,
                    perDay: perDay !== undefined && perDay > 0 ? perDay : 1,
                    maxCapa: maxCapa,
                    minCapa: minCapa,
                    timeGap: timeGap
                };
                formData.append('asset', JSON.stringify(assetInfo));

                /*if (assetBHRunnings && assetBHRunnings.length > 0) {
                    formData.append('assetRunningHours', JSON.stringify(assetBHRunnings));
                }*/

                const result = await registerAsset(formData);
                if (result) {
                    simpleAlert(t('names:notification'), t('names:createSuccess'), 'info');
                    resetPage();
                    navigate('/asset');
                }
            } else {
                simpleAlert(t('names:error'), t('names:missingEssential'), 'error');
            }
        } else {
            simpleAlert(t('names:error'), t('names:logoutStatus'), 'error');
            navigate('/login');
        }
    };

    return (
        <Box width="100%" alignItems="center" display="flex" flexDirection="column" maxWidth="740px">
            <Box width="100%" p={1}>
                <Typography fontSize="14px" fontWeight={400} color="black">
                    *{t('names:businesssiteInactivedCreated')}
                </Typography>
            </Box>
            <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }}>
                <Grid item xs={6}>
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:name')}
                        variant="outlined"
                        size="small"
                        value={name}
                        onChange={(e) => updateValue(e.target.value, 'name')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:typeName')}
                        variant="outlined"
                        size="small"
                        value={typeName}
                        onChange={(e) => updateValue(e.target.value, 'typeName')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth size="small">
                        <InputLabel>{t('names:type')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label={t('names:type')}
                            onChange={(selected) => {
                                if (selected && selected.target && selected.target.value) {
                                    setType(selected.target.value);
                                }
                            }}
                        >
                            <MenuItem value={'space'}>{t('names:space')}</MenuItem>
                            <MenuItem value={'service'}>{t('names:service')}</MenuItem>
                            <MenuItem value={'tservice'}>{t('names:timeService')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }}>
                <Grid item xs={5.75}>
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:minValue')}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">명</InputAdornment>
                        }}
                        value={minCapa}
                        onChange={(e) => setMinCapa(e.target.value ? Number(e.target.value) : undefined)}
                    />
                </Grid>
                <Grid item xs={0.5}>
                    <Typography fontSize="24px" fontWeight={900} color="black" sx={{ textAlign: 'center' }}>
                        ~
                    </Typography>
                </Grid>
                <Grid item xs={5.75}>
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:maxValue')}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">명</InputAdornment>
                        }}
                        value={maxCapa}
                        onChange={(e) => setMaxCapa(e.target.value ? Number(e.target.value) : undefined)}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }}>
                <Grid item xs={5.75}>
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:maxPerDay')}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">회</InputAdornment>
                        }}
                        value={perDay}
                        onChange={(e) => setPerDay(e.target.value ? Number(e.target.value) : undefined)}
                    />
                </Grid>
            </Grid>
            <Box p={1} width="100%">
                <TextField
                    id="outlined-basic"
                    label={t('names:description')}
                    variant="outlined"
                    size="small"
                    value={desc}
                    sx={{ width: '100%' }}
                    onChange={(e) => updateValue(e.target.value, 'desc')}
                />
            </Box>
            <Box p={1} width="100%">
                <TextField
                    id="outlined-basic"
                    label={t('names:timeGap')}
                    variant="outlined"
                    size="small"
                    type="number"
                    value={timeGap}
                    sx={{ width: '100%' }}
                    onChange={(e) => setTimeGap(e.target.value ? Number(e.target.value) : undefined)}
                />
            </Box>
            <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }} rowSpacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:address')}
                        variant="outlined"
                        disabled
                        size="small"
                        value={address}
                        sx={{ width: '100%' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        sx={{ p: 0 }}
                                        onClick={() => {
                                            handleClick();
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => updateValue(e.target.value, 'address')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:addressDetail')}
                        variant="outlined"
                        size="small"
                        value={addressDetail}
                        sx={{ width: '100%' }}
                        onChange={(e) => updateValue(e.target.value, 'addressDetail')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:howToCome')}
                        variant="outlined"
                        size="small"
                        value={howToCome}
                        sx={{ width: '100%' }}
                        onChange={(e) => updateValue(e.target.value, 'howToCome')}
                    />
                </Grid>
            </Grid>
            <Box width="100%" p={1}>
                <TextField
                    id="outlined-basic"
                    label={t('names:moreInfo')}
                    variant="outlined"
                    size="small"
                    value={moreInfo}
                    sx={{ width: '100%' }}
                    multiline
                    rows={4}
                    onChange={(e) => updateValue(e.target.value, 'moreInfo')}
                />
            </Box>
            <Box width="100%" p={1}>
                <TextField
                    id="outlined-basic"
                    label={t('names:personInCharge')}
                    variant="outlined"
                    size="small"
                    value={personInCharge}
                    sx={{ width: '100%' }}
                    onChange={(e) => updateValue(e.target.value, 'personInCharge')}
                />
            </Box>
            <Box mt={2} width="100%" p={1}>
                <Button
                    color="inherit"
                    size="small"
                    sx={{ border: 'solid', borderColor: '#CCCEDD', marginRight: '11px' }}
                    onClick={() => {
                        if (hiddenMainFileInputRef && hiddenMainFileInputRef.current) {
                            const fileInput = hiddenMainFileInputRef.current as HTMLInputElement;
                            fileInput.click();
                        }
                    }}
                    startIcon={<AttachFileIcon fontSize="small" color="primary" />}
                >
                    <Typography fontSize="14px" color="black">
                        {t('names:mainImageFile')}
                    </Typography>
                </Button>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={hiddenMainFileInputRef}
                    onChange={handleMainFileAttachClick}
                    accept="image/*"
                />
            </Box>
            <Box width="100%" p={1} display="flex">
                <Box
                    width="150px"
                    height="150px"
                    display="flex"
                    justifyContent="right"
                    sx={{
                        border: 'solid 1px #CCCEDD',
                        backgroundImage: mainImageUrl && mainImageUrl.length > 0 ? `url(${mainImageUrl})` : '',
                        backgroundSize: 'cover'
                    }}
                ></Box>
            </Box>
            <Box p={1} width="100%" mt={2}>
                <Button
                    color="inherit"
                    size="small"
                    sx={{ border: 'solid', borderColor: '#CCCEDD', marginRight: '11px' }}
                    onClick={() => {
                        if (hiddenFileInputRef && hiddenFileInputRef.current) {
                            const fileInput = hiddenFileInputRef.current as HTMLInputElement;
                            fileInput.click();
                        }
                    }}
                    startIcon={<AttachFileIcon fontSize="small" color="primary" />}
                >
                    <Typography fontSize="14px" color="black">
                        {t('names:attachImageFile')}
                    </Typography>
                </Button>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={hiddenFileInputRef}
                    onChange={handleFileAttachClick}
                    accept="image/*, application/pdf"
                />
            </Box>

            <Box width="100%" p={1} minHeight="155px" sx={{ border: 'solid 1px #CCCEDD' }}>
                <Grid container gap={1} justifyContent="left">
                    {imageUrls &&
                        imageUrls.map((url, index) => (
                            <Grid item key={index}>
                                <Box
                                    width="150px"
                                    height="150px"
                                    display="flex"
                                    justifyContent="right"
                                    sx={{
                                        border: 'solid 1px #CCCEDD',
                                        backgroundImage: `url(${url})`,
                                        backgroundSize: 'cover'
                                    }}
                                >
                                    <Box>
                                        <IconButton
                                            onClick={() => {
                                                removeImage(index);
                                            }}
                                        >
                                            <CloseIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                </Grid>
            </Box>

            <Box width="100%" p={2} display="flex" justifyContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        tryRegisterAsset();
                    }}
                >
                    {t('names:doRegister')}
                </Button>
            </Box>
        </Box>
    );
};
