import * as React from 'react';
import { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { colors } from 'src/theme/colors';
import { useUserProfile } from 'src/apis/hook/user';
import { useAssetNamesQuery } from 'src/apis/hook/asset';

import { useTranslation } from 'react-i18next';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const MemberAssetList: FC = () => {
    const { data: profile } = useUserProfile();
    const { t } = useTranslation();
    const { data: assetsNames } = useAssetNamesQuery();
    return (
        <Box
            sx={{ width: '100%' }}
            p={2}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={1}
        >
            <Typography fontWeight={900} fontSize={24}>
                {t('names:availableBusinessSite')}
            </Typography>
            {assetsNames &&
                assetsNames.length > 0 &&
                assetsNames.map((asset, index) => {
                    return (
                        <Box
                            key={`this-is-mass-${index}`}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: colors.main.gray00,
                                borderRadius: '5px'
                            }}
                            width="480px"
                            p={1}
                            alignItems={'center'}
                        >
                            <Box ml={3}>
                                <Box display="flex">
                                    <Typography mr={1}>{t('names:name')}:</Typography>
                                    <Typography>{asset.name}</Typography>
                                </Box>
                                <Box display="flex">
                                    <Typography mr={1}>{t('names:id')}:</Typography>
                                    <Typography>{asset.id}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
        </Box>
    );
};
