import * as React from 'react';
import { FC, useRef, useState } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserListStore } from 'src/store/user';
import { SmallTextField } from 'src/page/components/SmallTextField';
import { useAssetNamesQuery } from 'src/apis/hook/asset';

import dayjs from 'dayjs';
dayjs.locale('ko');

export const UserController: FC = () => {
    const { t } = useTranslation();
    const { search, setSearch } = useUserListStore();
    const [searchTemp, setSearchTextTemp] = useState<string>(search);

    return (
        <Box width="100%" alignItems="center" display="flex" flexDirection="column" maxWidth="1024px">
            <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }} rowGap={1}>
                <Grid item md={6} xs={12}>
                    <SmallTextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:title')}
                        variant="outlined"
                        size="small"
                        value={searchTemp}
                        onChange={(e) => setSearchTextTemp(e.target.value)}
                    />
                </Grid>
                <Grid item md={2} xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSearch(searchTemp);
                        }}
                    >
                        {t('names:search')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
