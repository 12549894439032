import React from 'react';
import { Box, IconButton } from '@mui/material';
import { AssetDetail } from './AddAssetDetail';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const AssetDetailContainer = () => {
    const navigate = useNavigate();
    return (
        <Box
            width="100%"
            height="calc(100svh - 75px)"
            sx={{
                scrollbarWidth: 'none', // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none' // Hide the scrollbar for IE
                }
            }}
        >
            <Box width="100%">
                <IconButton
                    onClick={() => {
                        navigate('/assets');
                    }}
                >
                    <ArrowBackIcon></ArrowBackIcon>
                </IconButton>
                <AssetDetail />
            </Box>
        </Box>
    );
};

export default AssetDetailContainer;
