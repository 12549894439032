import { create } from 'zustand';
import { User } from 'src/apis/type';

export interface UserStore {
    authenticated: boolean;
    profile: User | null;
    setAuthenticated: (value: boolean) => void;
    setProfile: (value: User) => void;
}

export const useUserStore = create<UserStore>((set) => ({
    authenticated: false,
    profile: null,
    setAuthenticated: (value: boolean) => set({ authenticated: value }),
    setProfile: (value: User) => set({ profile: value })
}));

export interface PaymentListStore {
    search: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    page: number;
    limit: number;
    setLimit: (value: number) => void;
    setPage: (value: number) => void;
    setSearch: (value: string) => void;
    setStartDate: (value: Date) => void;
    setEndDate: (value: Date) => void;
}
export const usePaymentListStore = create<PaymentListStore>((set) => ({
    page: 0,
    limit: 5,
    search: '',
    startDate: new Date(),
    endDate: (() => {
        const newDate = new Date();
        newDate.setMonth(newDate.getMonth() + 1);
        return newDate;
    })(),
    setLimit: (value: number) => set({ limit: value }),
    setPage: (value: number) => set({ page: value }),
    setSearch: (value: string) => set({ search: value }),
    setStartDate: (value: Date) => set({ startDate: value }),
    setEndDate: (value: Date) => set({ endDate: value })
}));
