import React from 'react';
import ReactDOM from 'react-dom';

import { FC, useState } from 'react';
import { Typography, Box, Popper, Button, IconButton, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'src/theme/colors';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogPorp {
    title: string;
    type: 'info' | 'error' | 'warn';
    content?: string;
    iconSupport?: boolean;
    open?: boolean;
    okText?: string;
    cancelText?: string;
    onOk?: () => void;
    onCancel?: () => void;
}

export const ConfirmDialog: FC<ConfirmDialogPorp> = ({
    open,
    type,
    title,
    content,
    onOk,
    onCancel,
    okText,
    cancelText,
    iconSupport
}) => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open ? open : false}
            PaperProps={{
                sx: {
                    height: '120px',
                    borderRadius: '10px',
                    background: type === 'info' ? colors.info : type === 'warn' ? colors.warn : colors.error
                }
            }}
        >
            <Box
                p={2}
                sx={{
                    background: type === 'info' ? colors.info : type === 'warn' ? colors.warn : colors.error,
                    minWidth: '250px',
                    maxWidth: '350px',
                    height: '100%',
                    borderRadius: '10px',
                    overflow: 'hidden'
                }}
            >
                <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                    {iconSupport && type === 'info' && (
                        <CheckCircleOutlineIcon sx={{ width: '20px', height: '20px', color: '#2E7D32' }} />
                    )}
                    {iconSupport && type === 'error' && (
                        <ErrorIcon sx={{ width: '20px', height: '20px', color: 'black' }} />
                    )}
                    {iconSupport && type === 'warn' && (
                        <ErrorOutlineIcon sx={{ width: '20px', height: '20px', color: 'white' }} />
                    )}

                    <Typography
                        fontFamily="Pretendard"
                        fontSize="14px"
                        fontWeight={700}
                        ml={1}
                        color={type == 'info' ? 'black' : 'black'}
                    >
                        {title}
                    </Typography>
                </Box>
                <Box
                    width="100%"
                    pl={1}
                    pr={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    sx={{
                        background: type === 'info' ? colors.info : type === 'warn' ? colors.warn : colors.error,
                        width: '100%',
                        height: '80%',
                        borderRadius: '10px'
                    }}
                >
                    <Typography fontFamily="Pretendard" fontSize="13px" sx={{ wordBreak: 'break-word' }}>
                        {content}
                    </Typography>
                </Box>
            </Box>
            <Box width="100%" justifyContent="center" display="flex" gap={2} p={1}>
                <Button
                    onClick={() => {
                        if (onOk) {
                            onOk();
                        }
                    }}
                    sx={{
                        width: '55px',
                        height: '30px',
                        borderRadius: '6px',
                        backgroundColor: 'blue',
                        color: 'white',
                        marginTop: '10px'
                    }}
                >
                    <AlertDialogTypo>{okText ? okText : t('names:confirm')}</AlertDialogTypo>
                </Button>
                <Button
                    onClick={() => {
                        if (onCancel) {
                            onCancel();
                        }
                    }}
                    sx={{
                        width: '55px',
                        height: '30px',
                        borderRadius: '6px',
                        backgroundColor: 'blue',
                        color: 'white',
                        marginTop: '10px'
                    }}
                >
                    <AlertDialogTypo>{cancelText ? cancelText : t('names:cancel')}</AlertDialogTypo>
                </Button>
            </Box>
        </Dialog>
    );
};

const AlertDialogTypo = styled(Typography)`
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 700;
`;

export const confirmDialog = (props: ConfirmDialogPorp): Promise<boolean> => {
    return new Promise((resolve) => {
        const div = document.createElement('div');
        document.body.appendChild(div);

        const handleOk = () => {
            resolve(true);
            cleanup();
        };

        const handleCancel = () => {
            resolve(false);
            cleanup();
        };

        const cleanup = () => {
            ReactDOM.unmountComponentAtNode(div);
            document.body.removeChild(div);
        };

        ReactDOM.render(<ConfirmDialog {...props} open={true} onOk={handleOk} onCancel={handleCancel} />, div);
    });
};
