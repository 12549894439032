import * as React from 'react';
import { FC, useRef, useState } from 'react';
import { Box, TextField, Typography, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { Membership } from 'src/apis/type';
import { useRegisterMembershipMutationQuery } from 'src/apis/hook/membership';
import { useAuthenticated } from 'src/apis/hook/user';
import { simpleAlert } from 'src/page/components/SimpleAlertDialog';
import { useNavigate } from 'react-router-dom';

export const AddMembershipEditor: FC = () => {
    const { mutateAsync: registerMembership } = useRegisterMembershipMutationQuery();
    const { data: authenticated } = useAuthenticated();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [code, setCode] = useState('');

    const [arrival, setArrival] = useState('');
    const [genesis, setGenesis] = useState('');

    const [price, setPrice] = useState<number | undefined>(undefined);
    const [currency, setCurrency] = useState('krw');
    const hiddenMainFileInputRef = useRef(null);

    const [mainImageFile, setMainImageFiles] = useState<any>();
    const [mainImageUrl, setMainImageUrl] = useState<string>();

    const handleMainFileAttachClick = (event: any) => {
        if (event.target.files.length > 0) {
            const reader = new FileReader();
            const file = event.target.files[0];
            setMainImageFiles(file);
            reader.onloadend = (e) => {
                if (e.target) {
                    setMainImageUrl(e.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const resetPage = () => {
        setTitle('');
        setMainImageFiles(undefined);
        setMainImageUrl('');
    };

    const tryRegisterAsset = async () => {
        if (authenticated) {
            if (code && code.length < 2) {
                simpleAlert({
                    title: t('names:error'),
                    content: t('names:goodsCodeNeed'),
                    type: 'info'
                });
                return;
            }
            if (title && title.length > 0 && price && price > 0 && code && code.length > 0) {
                const formData = new FormData();
                if (mainImageFile) {
                    formData.append('imageFile', mainImageFile, mainImageFile.name);
                }
                const memberInfo: Membership = {
                    title: title,
                    desc: desc,
                    price: price,
                    currency: currency,
                    arrival: arrival,
                    genesis: genesis,
                    code: code
                };
                formData.append('membership', JSON.stringify(memberInfo));
                const result = await registerMembership(formData);
                if (result) {
                    simpleAlert({
                        title: t('names:notification'),
                        content: t('names:successRegisterMembership'),
                        type: 'info'
                    });
                    resetPage();
                    navigate('/memberships');
                }
            } else {
                simpleAlert({ title: t('names:error'), content: t('names:missingEssential'), type: 'error' });
            }
        } else {
            simpleAlert({ title: t('names:error'), content: t('names:logoutStatus'), type: 'error' });
            navigate('/login');
        }
    };

    return (
        <Box width="100%" alignItems="center" display="flex" flexDirection="column" maxWidth="800px">
            <Box mt={1}>
                <Typography fontSize="24px" fontWeight={700} color="black">
                    {t('names:addMembership')}
                </Typography>
            </Box>
            <Grid container justifyContent="center" rowGap={1} spacing={1} sx={{ p: 1 }}>
                <Grid item xs={12}>
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:title')}
                        variant="outlined"
                        size="small"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:description')}
                        variant="outlined"
                        multiline
                        rows={4}
                        size="small"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:price')}
                        variant="outlined"
                        size="small"
                        value={price}
                        type="number"
                        onChange={(e) => setPrice(e.target.value ? Number(e.target.value) : undefined)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                        <InputLabel>{t('names:currency')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currency}
                            label={t('names:currency')}
                            onChange={(selected) => {
                                if (selected && selected.target && selected.target.value) {
                                    setCurrency(selected.target.value);
                                }
                            }}
                        >
                            <MenuItem value={'krw'}>{t('names:krw')}</MenuItem>
                            <MenuItem value={'usd'}>{t('names:usd')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box p={1} width="100%">
                <TextField
                    id="outlined-basic"
                    label={t('names:goodCode')}
                    variant="outlined"
                    size="small"
                    value={code}
                    required
                    sx={{ width: '100%' }}
                    onChange={(e) => setCode(e.target.value)}
                />
            </Box>
            <Box p={1} width="100%">
                <FormControl fullWidth size="small">
                    <InputLabel>{t('names:genesis')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={genesis}
                        label={t('names:genesis')}
                        onChange={(selected) => {
                            if (selected && selected.target && selected.target.value) {
                                setGenesis(selected.target.value);
                            }
                        }}
                    >
                        <MenuItem value={'experience'}>Experience</MenuItem>
                        <MenuItem value={'clubhouse'}>Clubhouse</MenuItem>
                        <MenuItem value={'talks'}>Talks</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box p={1} width="100%">
                <TextField
                    id="outlined-basic"
                    label={t('names:arrival')}
                    variant="outlined"
                    size="small"
                    value={arrival}
                    sx={{ width: '100%' }}
                    onChange={(e) => setArrival(e.target.value)}
                />
            </Box>
            <Box mt={2} width="100%" p={1}>
                <Button
                    color="inherit"
                    size="small"
                    sx={{ border: 'solid', borderColor: '#CCCEDD', marginRight: '11px' }}
                    onClick={() => {
                        if (hiddenMainFileInputRef && hiddenMainFileInputRef.current) {
                            const fileInput = hiddenMainFileInputRef.current as HTMLInputElement;
                            fileInput.click();
                        }
                    }}
                    startIcon={<AttachFileIcon fontSize="small" color="primary" />}
                >
                    <Typography fontSize="14px" color="black">
                        {t('names:mainImageFile')}
                    </Typography>
                </Button>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={hiddenMainFileInputRef}
                    onChange={handleMainFileAttachClick}
                    accept="image/*"
                />
            </Box>
            <Box width="100%" p={1} display="flex">
                <Box
                    width="150px"
                    height="150px"
                    display="flex"
                    justifyContent="right"
                    sx={{
                        border: 'solid 1px #CCCEDD',
                        backgroundImage: mainImageUrl && mainImageUrl.length > 0 ? `url(${mainImageUrl})` : '',
                        backgroundSize: 'cover'
                    }}
                ></Box>
            </Box>
            <Box width="100%" p={2} display="flex" justifyContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        tryRegisterAsset();
                    }}
                >
                    {t('names:doRegister')}
                </Button>
            </Box>
        </Box>
    );
};
