import * as React from 'react';
import { FC, useRef, useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography, FormControl, InputLabel, Grid, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useSimpleAlert } from 'src/store/ui';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { AssetRunnings } from 'src/apis/type';
import { convertMinutesToTime } from 'src/apis/ui';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';

export interface AddAssetRunningEditorProps {
    intiValue?: AssetRunnings;
    onChange?: (runnings: AssetRunnings) => void;
}
export const AddAssetRunningEditor: FC<AddAssetRunningEditorProps> = ({ onChange, intiValue }) => {
    const { alert: simpleAlert } = useSimpleAlert();
    const [assetRunnings, setAssetRunnings] = useState<AssetRunnings>([]);

    useEffect(() => {
        if (intiValue && assetRunnings.length == 0) {
            setAssetRunnings(intiValue);
        }
    }, [intiValue]);

    useEffect(() => {
        if (onChange) {
            onChange(assetRunnings);
        }
    }, [assetRunnings]);

    const deleteOfftime = (index: number, sindex: number) => {
        if (assetRunnings && assetRunnings[index] && assetRunnings[index].offTimes) {
            const assetRunning = assetRunnings[index];
            if (assetRunning.offTimes !== undefined) {
                const newArray = [...assetRunning.offTimes];
                newArray.splice(sindex, 1);
                assetRunnings[index].offTimes = newArray;
                setAssetRunnings([...assetRunnings]);
            }
        }
    };

    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');

    const hiddenMainFileInputRef = useRef(null);

    const [mainImageFile, setMainImageFiles] = useState<any>();

    const resetPage = () => {
        setTitle('');
        setLink('');
        setMainImageFiles(undefined);
    };

    const addEmptyAssetRunning = async () => {
        //emptyWday
        if (assetRunnings && assetRunnings.length == 7) {
            simpleAlert(t('names:error'), t('names:runningTimeLimit'), 'error');
            return;
        }
        if (assetRunnings && assetRunnings.length > 0) {
            let minWday = -1;
            const wdays = assetRunnings.map((running) => running.wday);

            for (let i = 1; i < wdays.length + 1; i++) {
                if (!wdays.includes(i)) {
                    minWday = i;
                    break;
                }
            }
            const empty = {
                wday: minWday,
                name: '',
                isRunning: true,
                startTime: 0,
                endTime: 1440
            };
            setAssetRunnings([...assetRunnings, empty]);
        } else {
            const empty = {
                wday: 0,
                name: '',
                isRunning: true,
                startTime: 0,
                endTime: 1440
            };
            setAssetRunnings([...assetRunnings, empty]);
        }
    };

    return (
        <Box width="100%" alignItems="center" display="flex" flexDirection="column" p={1} pb={10}>
            {assetRunnings.length == 0 && (
                <Box gap={1} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            addEmptyAssetRunning();
                        }}
                        sx={{ minWidth: '10px', minHeight: '10px', width: '40px', height: '40px' }}
                        size="small"
                    >
                        <AddIcon />
                    </Button>
                </Box>
            )}
            {assetRunnings &&
                assetRunnings.length > 0 &&
                assetRunnings.map((running, index) => {
                    return (
                        <Box
                            width="100%"
                            p={1}
                            mt={1}
                            sx={{ border: '1px solid #BABABA', borderRadius: '10px' }}
                            key={`grid-asset-running-${index}`}
                        >
                            <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }}>
                                <Grid item xs={1.5}>
                                    <Box height="100%" width="100%" alignItems="center" display="flex">
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id="demo-select-small-label">{t('names:weekDay')}</InputLabel>
                                            <Select
                                                value={running.wday}
                                                label={t('names:weekDay')}
                                                onChange={(selectEvent) => {
                                                    const floatWday = assetRunnings.map((running, index) => {
                                                        return running.wday;
                                                    });
                                                    if (selectEvent && selectEvent.target) {
                                                        if (floatWday.includes(Number(selectEvent.target.value))) {
                                                            simpleAlert(
                                                                t('names:error'),
                                                                t('names:runningWdayTimeDuplicate'),
                                                                'error'
                                                            );
                                                            return;
                                                        }
                                                        const newValue = {
                                                            ...running,
                                                            wday: Number(selectEvent.target.value)
                                                        };
                                                        setAssetRunnings([
                                                            ...assetRunnings.slice(0, index),
                                                            newValue,
                                                            ...assetRunnings.slice(index + 1)
                                                        ]);
                                                    }
                                                }}
                                            >
                                                <MenuItem value={0}>{t('names:monday')}</MenuItem>
                                                <MenuItem value={1}>{t('names:tuesday')}</MenuItem>
                                                <MenuItem value={2}>{t('names:wednesday')}</MenuItem>
                                                <MenuItem value={3}>{t('names:thursday')}</MenuItem>
                                                <MenuItem value={4}>{t('names:friday')}</MenuItem>
                                                <MenuItem value={5}>{t('names:saturday')}</MenuItem>
                                                <MenuItem value={6}>{t('names:sunday')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={4.25}>
                                    <Box width="100%" height="100%" display="flex" alignItems="center">
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                                            <TimePicker
                                                value={convertMinutesToTime(running.startTime)}
                                                sx={{ width: '100%' }}
                                                label={t('names:startTime')}
                                                ampm={false}
                                                onChange={(date) => {
                                                    if (date) {
                                                        const hour = date.get('hour');
                                                        const minute = date.get('minute');
                                                        const totalMinutes = hour * 60 + minute;
                                                        const newValue = {
                                                            ...running,
                                                            startTime: totalMinutes
                                                        };
                                                        setAssetRunnings([
                                                            ...assetRunnings.slice(0, index),
                                                            newValue,
                                                            ...assetRunnings.slice(index + 1)
                                                        ]);
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={4.25}>
                                    <Box width="100%" height="100%" display="flex" alignItems="center">
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                                            <TimePicker
                                                value={convertMinutesToTime(running.endTime)}
                                                sx={{ width: '100%' }}
                                                label={t('names:endTime')}
                                                ampm={false}
                                                onChange={(date) => {
                                                    if (date) {
                                                        const hour = date.get('hour');
                                                        const minute = date.get('minute');
                                                        const totalMinutes = hour * 60 + minute;
                                                        const newValue = {
                                                            ...running,
                                                            endTime: totalMinutes
                                                        };
                                                        setAssetRunnings([
                                                            ...assetRunnings.slice(0, index),
                                                            newValue,
                                                            ...assetRunnings.slice(index + 1)
                                                        ]);
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>

                                <Grid item xs={2}>
                                    <Box
                                        gap={1}
                                        width="100%"
                                        height="100%"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        {index == assetRunnings.length - 1 && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    addEmptyAssetRunning();
                                                }}
                                                sx={{
                                                    minWidth: '10px',
                                                    minHeight: '10px',
                                                    width: '40px',
                                                    height: '40px'
                                                }}
                                                size="small"
                                            >
                                                <AddIcon />
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                if (running.offTimes && running.offTimes.length > 0) {
                                                    running.offTimes = [
                                                        ...running.offTimes,
                                                        { startTime: undefined, endTime: undefined }
                                                    ];
                                                } else {
                                                    running.offTimes = [{ startTime: undefined, endTime: undefined }];
                                                }
                                                setAssetRunnings([
                                                    ...assetRunnings.slice(0, index),
                                                    running,
                                                    ...assetRunnings.slice(index + 1)
                                                ]);
                                            }}
                                            color="info"
                                            sx={{ minWidth: '10px', minHeight: '10px', width: '40px', height: '40px' }}
                                            size="small"
                                        >
                                            <AlarmOffIcon />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setAssetRunnings([
                                                    ...assetRunnings.slice(0, index),
                                                    ...assetRunnings.slice(index + 1)
                                                ]);
                                            }}
                                            color="error"
                                            sx={{ minWidth: '10px', minHeight: '10px', width: '40px', height: '40px' }}
                                            size="small"
                                        >
                                            <ClearIcon />
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            {running.offTimes &&
                                running.offTimes.length > 0 &&
                                running.offTimes.map((item, ofindex) => {
                                    return (
                                        <Grid container spacing={1} sx={{ p: 1 }} key={`index-offtimes-keys`}>
                                            <Grid item xs={1.5}></Grid>
                                            <Grid item xs={4.25}>
                                                <Box width="100%" height="100%" display="flex" alignItems="center">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                                                        <TimePicker
                                                            value={convertMinutesToTime(item.startTime)}
                                                            sx={{ width: '100%' }}
                                                            label={t('names:offStartTime')}
                                                            ampm={false}
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    const hour = date.get('hour');
                                                                    const minute = date.get('minute');
                                                                    const totalMinutes = hour * 60 + minute;
                                                                    if (
                                                                        totalMinutes >= running.startTime &&
                                                                        totalMinutes <= running.endTime
                                                                    ) {
                                                                        item.startTime = totalMinutes;
                                                                    } else {
                                                                        simpleAlert(
                                                                            t('names:error'),
                                                                            t('names:timeReangeError'),
                                                                            'error'
                                                                        );
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4.25}>
                                                <Box width="100%" height="100%" display="flex" alignItems="center">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                                                        <TimePicker
                                                            value={convertMinutesToTime(item.endTime)}
                                                            sx={{ width: '100%' }}
                                                            label={t('names:offEndTime')}
                                                            ampm={false}
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    const hour = date.get('hour');
                                                                    const minute = date.get('minute');
                                                                    const totalMinutes = hour * 60 + minute;
                                                                    if (
                                                                        totalMinutes >
                                                                            (item.startTime ? item.startTime : 0) &&
                                                                        totalMinutes >= running.startTime &&
                                                                        totalMinutes <= running.endTime
                                                                    ) {
                                                                        item.endTime = totalMinutes;
                                                                    } else {
                                                                        simpleAlert(
                                                                            t('names:error'),
                                                                            t('names:timeReangeError'),
                                                                            'error'
                                                                        );
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Box
                                                    gap={1}
                                                    width="100%"
                                                    height="100%"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            //delete offtime
                                                            console.log('delete offtime: ', index, ofindex);
                                                            deleteOfftime(index, ofindex);
                                                            /*if (running.offTimes) {
                                                                running.offTimes.splice(index, 1);
                                                                running.offTimes = [...running.offTimes];
                                                            }*/
                                                        }}
                                                        color="error"
                                                        sx={{
                                                            minWidth: '10px',
                                                            minHeight: '10px',
                                                            width: '40px',
                                                            height: '40px'
                                                        }}
                                                        size="small"
                                                    >
                                                        <ClearIcon />
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                        </Box>
                    );
                })}
        </Box>
    );
};
