import * as React from 'react';
import { FC, useRef, useState } from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    Grid,
    IconButton,
    Divider,
    Checkbox,
    FormControlLabel,
    FormGroup
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { Room } from 'src/apis/type';
import { useRegisterAssetRoomMutationQuery } from 'src/apis/hook/asset';
import { useAuthenticated } from 'src/apis/hook/user';
import { useSimpleAlert } from 'src/store/ui';
import { useNavigate } from 'react-router-dom';
import { CounterInput } from 'src/page/components/CounterInput';
import { useAssetsUI } from 'src/store/ui';

export const AddAssetRoomEditor: FC = () => {
    const { mutateAsync: registerAssetRoom } = useRegisterAssetRoomMutationQuery();
    const { data: authenticated } = useAuthenticated();
    const { alert: simpleAlert } = useSimpleAlert();
    const { selectedAsset } = useAssetsUI();

    const navigate = useNavigate();

    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [type, setType] = useState('space');
    const [minCapa, setMinCapa] = useState(1);
    const [maxCapa, setMaxCapa] = useState(1);
    const [isAvailable, setIsAvailable] = useState(false);
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [personInCharge, setPersonInCharge] = useState('');
    const [howToCome, setHowToCome] = useState<string>();
    const [moreInfo, setMoreInfo] = useState<string>();

    const hiddenFileInputRef = useRef(null);
    const hiddenMainFileInputRef = useRef(null);

    const [imageFiles, setImageFiles] = useState<any[]>([]);
    const [imageUrls, setImageUrls] = useState<string[]>([]);

    const handleFileAttachClick = (event: any) => {
        if (event.target.files.length > 0) {
            const reader = new FileReader();
            const file = event.target.files[0];
            setImageFiles([...imageFiles, file]);
            reader.onloadend = (e) => {
                if (e.target) {
                    setImageUrls([...imageUrls, e.target.result as string]);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const removeImage = (index: number) => {
        const newImageFiles = imageFiles.filter((_, i) => i !== index);
        const newImageUrls = imageUrls.filter((_, i) => i !== index);
        setImageFiles(newImageFiles);
        setImageUrls(newImageUrls);
    };

    const resetPage = () => {
        setName('');
        setDesc('');
        setType('');
        setAddress('');
        setAddressDetail('');
        setPersonInCharge('');
        setHowToCome('');
        setMoreInfo('');
        setImageFiles([]);
        setImageUrls([]);
    };

    const updateValue = async (value: string, mode: string) => {
        if (mode === 'name') {
            setName(value);
        } else if (mode === 'desc') {
            setDesc(value);
        } else if (mode === 'type') {
            setType(value);
        } else if (mode === 'addressDetail') {
            setAddressDetail(value);
        } else if (mode === 'personInCharge') {
            setPersonInCharge(value);
        } else if (mode === 'howToCome') {
            setHowToCome(value);
        } else if (mode === 'moreInfo') {
            setMoreInfo(value);
        }
    };

    const tryRegisterAssetRoom = async () => {
        if (authenticated && selectedAsset && selectedAsset.id && selectedAsset._id) {
            if (name && name.length > 0 && imageFiles.length > 0) {
                const formData = new FormData();
                imageFiles.forEach((item, index) => {
                    formData.append('imageFiles', item, `${index}-${item.name}`);
                });

                const assetInfo: Room = {
                    name: name,
                    desc: desc,
                    contact: personInCharge,
                    moreInfo: moreInfo,
                    maxCapa: maxCapa,
                    isAvailable: isAvailable,
                    minCapa: minCapa
                };
                formData.append('room', JSON.stringify(assetInfo));
                formData.append('assetId', selectedAsset._id);
                const result = await registerAssetRoom(formData);
                if (result) {
                    simpleAlert(t('names:notification'), t('names:successRegisterAssetRoom'), 'info');
                    resetPage();
                    navigate('/asset');
                }
            } else {
                simpleAlert(t('names:error'), t('names:missingEssential'), 'error');
            }
        } else {
            simpleAlert(t('names:error'), t('names:logoutStatus'), 'error');
            navigate('/login');
        }
    };

    return (
        <Box width="100%" alignItems="center" display="flex" flexDirection="column" maxWidth="800px">
            <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }}>
                <Grid item xs={12}>
                    <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:name')}
                        variant="outlined"
                        size="small"
                        value={name}
                        onChange={(e) => updateValue(e.target.value, 'name')}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }}>
                <Grid item xs={5.75}>
                    <Box display="flex" alignItems="center" width="100%">
                        <Box mr={1}>
                            <Typography fontSize="16px" fontWeight={500} color="black">
                                {t('names:minValue')}
                            </Typography>
                        </Box>
                        <Box flexGrow={1}>
                            <CounterInput
                                value={minCapa}
                                placeholder={t('names:minValue')}
                                onChange={(v) => {
                                    setMinCapa(v);
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={0.5}>
                    <Typography fontSize="24px" fontWeight={900} color="black" sx={{ textAlign: 'center' }}>
                        ~
                    </Typography>
                </Grid>
                <Grid item xs={5.75}>
                    <Box display="flex" alignItems="center" width="100%">
                        <Box mr={1}>
                            <Typography fontSize="16px" fontWeight={500} color="black">
                                {t('names:maxValue')}
                            </Typography>
                        </Box>
                        <Box flexGrow={1}>
                            <CounterInput
                                value={maxCapa}
                                placeholder={t('names:maxValue')}
                                onChange={(v) => {
                                    setMaxCapa(v);
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box p={1} width="100%">
                <TextField
                    id="outlined-basic"
                    label={t('names:description')}
                    variant="outlined"
                    size="small"
                    value={desc}
                    multiline
                    rows={3}
                    sx={{ width: '100%' }}
                    onChange={(e) => updateValue(e.target.value, 'desc')}
                />
            </Box>

            <Box width="100%" p={1}>
                <TextField
                    id="outlined-basic"
                    label={t('names:moreInfo')}
                    variant="outlined"
                    size="small"
                    value={moreInfo}
                    sx={{ width: '100%' }}
                    multiline
                    rows={4}
                    onChange={(e) => updateValue(e.target.value, 'moreInfo')}
                />
            </Box>
            <Box width="100%" p={1}>
                <TextField
                    id="outlined-basic"
                    label={t('names:personInCharge')}
                    variant="outlined"
                    size="small"
                    value={personInCharge}
                    sx={{ width: '100%' }}
                    onChange={(e) => updateValue(e.target.value, 'personInCharge')}
                />
            </Box>

            <Box>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isAvailable}
                                onChange={(__, check) => {
                                    setIsAvailable(check);
                                }}
                            />
                        }
                        label={t('names:isAvailable')}
                    />
                </FormGroup>
            </Box>
            <Box p={1} width="100%" mt={2}>
                <Button
                    color="inherit"
                    size="small"
                    sx={{ border: 'solid', borderColor: '#CCCEDD', marginRight: '11px' }}
                    onClick={() => {
                        if (hiddenFileInputRef && hiddenFileInputRef.current) {
                            const fileInput = hiddenFileInputRef.current as HTMLInputElement;
                            fileInput.click();
                        }
                    }}
                    startIcon={<AttachFileIcon fontSize="small" color="primary" />}
                >
                    <Typography fontSize="14px" color="black">
                        {t('names:attachImageFile')}
                    </Typography>
                </Button>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={hiddenFileInputRef}
                    onChange={handleFileAttachClick}
                    accept="image/*, application/pdf"
                />
            </Box>

            <Box width="100%" p={1} minHeight="155px" sx={{ border: 'solid 1px #CCCEDD' }}>
                <Grid container gap={1} justifyContent="left">
                    {imageUrls &&
                        imageUrls.map((url, index) => (
                            <Grid item key={index}>
                                <Box
                                    width="150px"
                                    height="150px"
                                    display="flex"
                                    justifyContent="right"
                                    sx={{
                                        border: 'solid 1px #CCCEDD',
                                        backgroundImage: `url(${url})`,
                                        backgroundSize: 'cover'
                                    }}
                                >
                                    <Box>
                                        <IconButton
                                            onClick={() => {
                                                removeImage(index);
                                            }}
                                        >
                                            <CloseIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                </Grid>
            </Box>

            <Box width="100%" mt={5}>
                <Divider />
            </Box>
            <Box width="100%" p={2} display="flex" justifyContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        tryRegisterAssetRoom();
                    }}
                >
                    {t('names:doRegister')}
                </Button>
            </Box>
        </Box>
    );
};
