export const colors = {
    main: {
        'OWL-BK': 'white',
        gray00: '#BABABA',
        gray01: '#BABABB',
        gray02: '#828282',
        gray03: '#5D5E5D',
        gray04: '#393939',
        gray05: '#111212',
        grayCards: '#252424'
    },
    info: '#FFFFFF',
    warn: '#ff9800',
    error: '#FDEDED',
    sub02: '#ff9800',
    subGray: '#ABABAB',
    textGray: '#2E2E2E',
    madRed: '#D40300',
    mainBlue: '#0A84FF'
};
