import * as React from 'react';
import { FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import { useGetUsers, useGetUsersCount } from 'src/apis/hook/user';
import { useUserListStore } from 'src/store/user';
import { Asset } from 'src/apis/type';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeleteArchiveMutationQuery } from 'src/apis/hook/archive';
import { useSimpleAlert } from 'src/store/ui';

export const UserList: FC = () => {
    const { search, page, limit, setLimit, setPage } = useUserListStore();
    const { data: users, refetch: refetchUser } = useGetUsers(search, page, limit);
    const { data: count } = useGetUsersCount(search);

    const { mutateAsync: deleteArchive } = useDeleteArchiveMutationQuery();
    const { alert: simpleAlert } = useSimpleAlert();

    const navigator = useNavigate();
    const { t } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: '생성일',
            flex: 1,
            minWidth: 50,
            renderCell: (params) => {
                return new Date(params.row.createdAt).toLocaleDateString();
            }
        },
        { field: 'name', headerName: t('names:name'), flex: 2, minWidth: 100 },
        { field: 'email', headerName: t('names:email'), flex: 3, minWidth: 100 },
        { field: 'level', headerName: t('names:level'), flex: 3, minWidth: 100 },
        { field: 'userWalletAddress', headerName: t('names:wallet'), flex: 3, minWidth: 100 }
    ];

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {users && users.length > 0 && (
                <DataGrid
                    rows={users}
                    columns={columns}
                    getRowId={(row) => row._id}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: t('names:rowsPerPage')
                        }
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 }
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                brokerId: false,
                                status: false
                            }
                        }
                    }}
                    onPaginationModelChange={(pagination) => {
                        setLimit(pagination.pageSize);
                        setPage(pagination.page);
                    }}
                    rowCount={count}
                    pageSizeOptions={[5, 10]}
                />
            )}
        </div>
    );
};
