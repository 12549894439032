import React from 'react';
import { FC } from 'react';
import { Typography, Box, Button, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactDOM from 'react-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
interface ColoredConfirmDialogPorp {
    title: string;
    type?: 'info' | 'error' | 'warn';
    backgroundColor?: string;
    color?: string;
    content?: string;
    open?: boolean;
    okText?: string;
    cancelText?: string;
    onOk?: () => void;
    onCancel?: () => void;
}

export const ColoredConfirmDialog: FC<ColoredConfirmDialogPorp> = ({
    open,
    backgroundColor,
    type,
    title,
    content,
    onOk,
    onCancel,
    okText,
    cancelText,
}) => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open ? open : false}
            PaperProps={{
                sx: {
                    maxWidth: '400px',
                    width: '100%',
                    height: '120px',
                    borderRadius: '10px',
                    background: backgroundColor ? backgroundColor : '#CCFFBE',
                },
            }}
        >
            <Box
                p={1.5}
                sx={{
                    minWidth: '250px',
                    maxWidth: '400px',
                    height: '100%',
                    borderRadius: '10px',
                    overflow: 'hidden',
                }}
            >
                <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="left"
                >
                    {type === 'info' ||
                        (type == undefined && (
                            <InfoOutlinedIcon
                                sx={{
                                    width: '16px',
                                    height: '16px',
                                    color: '#282828',
                                }}
                            />
                        ))}
                    {type === 'error' && (
                        <ErrorIcon
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: '#282828',
                            }}
                        />
                    )}
                    {type === 'warn' && (
                        <ErrorOutlineIcon
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: '#282828',
                            }}
                        />
                    )}

                    <Typography
                        fontFamily="Pretendard"
                        fontSize="14px"
                        fontWeight={700}
                        ml={0.5}
                        color={type == 'info' ? 'black' : 'black'}
                    >
                        {title}
                    </Typography>
                </Box>
                <Box
                    width="100%"
                    pl={1}
                    pr={1}
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        borderRadius: '10px',
                    }}
                >
                    <Typography
                        fontFamily="Pretendard"
                        fontSize="11px"
                        sx={{ wordBreak: 'break-word' }}
                    >
                        {content}
                    </Typography>
                </Box>
            </Box>
            <Box
                width="100%"
                justifyContent="right"
                display="flex"
                gap={2}
                p={1}
            >
                <DialogButton
                    onClick={() => {
                        if (onOk) {
                            onOk();
                        }
                    }}
                    sx={{
                        width: '64px',
                        height: '24px',
                    }}
                >
                    {okText ? okText : t('names:confirm')}
                </DialogButton>
                <DialogButton
                    onClick={() => {
                        if (onCancel) {
                            onCancel();
                        }
                    }}
                    sx={{
                        width: '64px',
                        height: '24px',
                    }}
                >
                    {cancelText ? cancelText : t('names:cancel')}
                </DialogButton>
            </Box>
        </Dialog>
    );
};

const DialogButton = styled(Button)`
    font-family: Inter;
    font-size: 11px;
    font-weight: 400;
    border-radius: 16px;
    color: white;
    background-color: black;

    &:focus,
    &:active {
        background-color: black;
        color: white;
    }

    &:hover {
        border: 1px solid #00ff00;
        color: #00ff00;
    }
`;

export const comfirmDialog = (
    props: ColoredConfirmDialogPorp,
): Promise<boolean> => {
    return new Promise(resolve => {
        const div = document.createElement('div');
        document.body.appendChild(div);

        const handleOk = () => {
            resolve(true);
            cleanup();
        };

        const handleCancel = () => {
            resolve(false);
            cleanup();
        };

        const cleanup = () => {
            ReactDOM.unmountComponentAtNode(div);
            document.body.removeChild(div);
        };

        ReactDOM.render(
            <ColoredConfirmDialog
                {...props}
                open={true}
                onOk={handleOk}
                onCancel={handleCancel}
            />,
            div,
        );
    });
};
