import React from 'react';
import { FC, useState } from 'react';
import { Typography, Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'src/theme/colors';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorIcon from '@mui/icons-material/Error';
import ReactDOM from 'react-dom';

interface AlertProp {
    title: string;
    type: 'info' | 'error' | 'warn';
    content?: string;
    iconSupport?: boolean;
    open?: boolean;
    onClose?: () => void;
}

export const SimpleAlertDialog: FC<AlertProp> = ({ open, type, title, content, onClose, iconSupport }) => {
    return (
        <Dialog
            open={open ? open : false}
            sx={{
                zIndex: 1202
            }}
            PaperProps={{
                sx: {
                    height: '100px',
                    borderRadius: '10px',
                    background: type === 'info' ? colors.info : type === 'warn' ? colors.warn : colors.error
                }
            }}
            onClick={() => {
                if (onClose) {
                    onClose();
                }
            }}
            onClose={() => {
                if (onClose) {
                    onClose();
                }
            }}
        >
            <Box
                p={2}
                sx={{
                    background: type === 'info' ? colors.info : type === 'warn' ? colors.warn : colors.error,
                    minWidth: '250px',
                    maxWidth: '350px',
                    height: '100%',
                    borderRadius: '10px',
                    overflow: 'hidden'
                }}
            >
                <Box width="100%" display="flex" alignItems="center" justifyContent="left">
                    {iconSupport && type === 'info' && (
                        <CheckCircleOutlineIcon
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: '#2E7D32'
                            }}
                        />
                    )}
                    {iconSupport && type === 'error' && (
                        <ErrorIcon
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: '#AB2306'
                            }}
                        />
                    )}
                    {iconSupport && type === 'warn' && (
                        <ErrorOutlineIcon
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: 'white'
                            }}
                        />
                    )}

                    <Typography
                        fontFamily="Pretendard"
                        fontSize="14px"
                        fontWeight={700}
                        ml={1}
                        color={type == 'info' ? 'black' : '#AB2306'}
                    >
                        {title}
                    </Typography>
                </Box>
                <Box
                    width="100%"
                    pl={1}
                    pr={1}
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    sx={{
                        background: type === 'info' ? colors.info : type === 'warn' ? colors.warn : colors.error,
                        width: '100%',
                        height: '80%',
                        borderRadius: '10px'
                    }}
                >
                    <Typography fontFamily="Pretendard" fontSize="11px" sx={{ wordBreak: 'break-word' }}>
                        {content}
                    </Typography>
                </Box>
            </Box>
        </Dialog>
    );
};

export const simpleAlert = (props: AlertProp): Promise<boolean> => {
    return new Promise((resolve) => {
        const div = document.createElement('div');
        document.body.appendChild(div);
        const handleClose = () => {
            resolve(false);
            cleanup();
        };

        const cleanup = () => {
            ReactDOM.unmountComponentAtNode(div);
            document.body.removeChild(div);
        };

        ReactDOM.render(<SimpleAlertDialog {...props} open={true} onClose={handleClose} iconSupport />, div);
    });
};
