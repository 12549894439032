import React from 'react';
import { useState, useCallback } from 'react';
import { Box, Typography, TextField, FormControl, MenuItem, InputLabel, Select, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HeaderAddActionButton } from 'src/page/components/HeaderAddActionButton';
import { isEthereumAddress } from 'src/apis/ui';
import { useMintGenesisMutateQuery } from 'src/apis/hook/nft';
import { simpleAlert } from '../components/SimpleAlertDialog';

const NFTMembershipContainer = () => {
    const { t } = useTranslation();
    const [address, setAddress] = useState('');
    const [genesis, setGenesis] = useState('');
    const { mutateAsync: mintGenesisAsync } = useMintGenesisMutateQuery();
    const tryMint = useCallback(async () => {
        if (address == '' || !isEthereumAddress(address)) {
            simpleAlert({
                title: t('names:error'),
                content: t('names:errorWallet'),
                type: 'error'
            });
            return;
        }
        if (genesis !== 'mars') {
            simpleAlert({
                title: t('names:error'),
                content: t('names:errorType'),
                type: 'error'
            });
            return;
        }
        try {
            const result = await mintGenesisAsync({ wallet: address, type: genesis });
            if (!result) {
                simpleAlert({
                    title: t('names:error'),
                    content: t('names:errorMint'),
                    type: 'error'
                });
                return;
            } else {
                simpleAlert({
                    title: t('names:success'),
                    content: t('names:successMint'),
                    type: 'info'
                });
                setAddress('');
                setGenesis('');
            }
        } catch (e) {
            simpleAlert({
                title: t('names:error'),
                content: t('names:errorMint'),
                type: 'error'
            });
        }
    }, [address, genesis]);
    return (
        <Box
            width="100%"
            height="calc(100svh - 75px)"
            sx={{
                overflowY: 'scroll',
                scrollbarWidth: 'none', // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none' // Hide the scrollbar for IE
                }
            }}
            display="flex"
            flexDirection={'column'}
        >
            <Box width="100%" p={1} display="flex" alignItems="center">
                <Box>
                    <Typography fontSize="20px" fontWeight={700}>
                        {t('names:mintnft')}
                    </Typography>
                </Box>
                <HeaderAddActionButton mode="iconButton" routerPath="/memberships/add" />
            </Box>
            <Box p={1}>
                <Box p={1} width="100%">
                    <TextField
                        error={!(address == '') && !isEthereumAddress(address)}
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label={t('names:walletAddress')}
                        variant="outlined"
                        size="small"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </Box>
                <Box p={1} width="100%">
                    <FormControl fullWidth size="small">
                        <InputLabel>{t('names:genesis')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={genesis}
                            label={t('names:genesis')}
                            onChange={(selected) => {
                                if (selected && selected.target && selected.target.value) {
                                    setGenesis(selected.target.value);
                                }
                            }}
                        >
                            <MenuItem value={'mars'}>RSV Club</MenuItem>
                            {/*<MenuItem value={'experience'}>Experience</MenuItem>
                            <MenuItem value={'clubhouse'}>Clubhouse</MenuItem>
                            <MenuItem value={'talks'}>Talks</MenuItem>*/}
                        </Select>
                    </FormControl>
                </Box>
                <Box width="100%" p={2} display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            tryMint();
                        }}
                    >
                        {t('names:doRegister')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default NFTMembershipContainer;
