import * as React from 'react';
import { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { colors } from 'src/theme/colors';
import { useUserProfile } from 'src/apis/hook/user';
import { useTranslation } from 'react-i18next';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const MemberCard: FC = () => {
    const { data: profile } = useUserProfile();
    const { t } = useTranslation();
    return (
        <Box sx={{ width: '100%' }} p={2} justifyContent="center" display="flex">
            <Box
                sx={{ display: 'flex', flexDirection: 'row', backgroundColor: colors.main.gray00, borderRadius: '5px' }}
                width="480px"
                height="140px"
                p={1}
                alignItems={'center'}
            >
                <Box width="100px" height="100px">
                    <AdminPanelSettingsIcon sx={{ width: '100px', height: '100px' }} />
                </Box>
                {profile && (
                    <Box ml={3}>
                        <Typography fontSize="18px">{`LEVEL: ${profile.level?.toLocaleUpperCase()}`}</Typography>
                        <Box display="flex">
                            <Typography mr={1}>{t('names:name')}:</Typography>
                            <Typography>{profile.name}</Typography>
                        </Box>
                        <Box display="flex">
                            <Typography mr={1}>{t('names:email')}:</Typography>
                            <Typography>{profile.email}</Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
