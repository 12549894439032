import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import './locale';
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            retry: 1,
            staleTime: 5 * 1000
        }
    }
});

root.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <App />
        </QueryClientProvider>
    </BrowserRouter>
);
