import React from 'react';
import { FC } from 'react';
import { Box, IconButton, Typography, Chip } from '@mui/material';
import { AddAssetRoomEditor } from './AddAssetRoomEditor';
import { UpdateAssetRoomEditor } from './update/UpdateAssetRoomEditor';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useAssetsUI } from 'src/store/ui';
interface AssetRoomsEditorProps {
    mode?: 'add' | 'update';
}

const AssetRoomsEditorContainer: FC<AssetRoomsEditorProps> = ({ mode }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { selectedAsset } = useAssetsUI();

    return (
        <Box
            width="100%"
            height="calc(100svh - 80px)"
            display={'flex'}
            flexDirection={'column'}
            sx={{
                scrollbarWidth: 'none', // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none' // Hide the scrollbar for IE
                }
            }}
        >
            <Box width="100%" display="flex" alignItems="center" mt={1} mb={1}>
                <Box>
                    <IconButton
                        onClick={() => {
                            navigate('/asset');
                        }}
                    >
                        <ArrowBackIcon></ArrowBackIcon>
                    </IconButton>
                </Box>
                <Box ml={1}>
                    <Typography fontSize="18px" fontWeight={700} color="black">
                        {`${t('names:reservedSpace')} ${mode === 'update' ? t('names:update') : t('names:add')}:`}
                    </Typography>
                </Box>
                <Box ml={2}>
                    <Chip
                        label={
                            <Typography fontSize="13px" fontWeight={700} color="white">
                                {`${t('names:businessSite')}: ${selectedAsset && selectedAsset.name}`}
                            </Typography>
                        }
                        color="primary"
                    />
                </Box>
            </Box>
            <Box width="100%" height="100%" display="flex" flexDirection="column" overflow="scroll">
                <Box width="100%" display="flex" justifyContent="center">
                    {mode && mode == 'update' ? <UpdateAssetRoomEditor /> : <AddAssetRoomEditor />}
                </Box>
            </Box>
        </Box>
    );
};

export default AssetRoomsEditorContainer;
