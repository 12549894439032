import * as React from 'react';
import { FC, useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
    List,
    Drawer,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Chip,
    Typography,
    colors
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InputIcon from '@mui/icons-material/Input';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useAuthenticated, useLogoutMutationQuery, useUserProfile } from 'src/apis/hook/user';
import { useSimpleAlert } from 'src/store/ui';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ArchiveIcon from '@mui/icons-material/Archive';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddCardIcon from '@mui/icons-material/AddCard';
import SettingsIcon from '@mui/icons-material/Settings';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
interface DrawerMenuProps {
    open: boolean;
    onClose: () => void;
}
interface SelectableListItemProps {
    icon: React.ReactElement;
    label: string;
    value: string;
    selectedValue: string;
    onClick: () => void;
}

const SelectableListItem: FC<SelectableListItemProps> = ({ icon, label, value, selectedValue, onClick }) => {
    const modifiedIcon = React.cloneElement(icon, {
        sx: { color: value === selectedValue ? 'white' : '' }
    });

    return (
        <ListItem disablePadding>
            <ListItemButton
                sx={{ backgroundColor: value === selectedValue ? '#2D3748' : '', borderRadius: '8px' }}
                onClick={() => {
                    onClick();
                }}
            >
                <ListItemIcon>{modifiedIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            fontFamily="Pretendard"
                            fontSize="16px"
                            fontWeight={value === selectedValue ? 900 : 600}
                            color={value === selectedValue ? 'white' : ''}
                        >
                            {label}
                        </Typography>
                    }
                />
            </ListItemButton>
        </ListItem>
    );
};

export const DrawerMenu: FC<DrawerMenuProps> = ({ open, onClose }) => {
    const { data: authenticated, refetch: refetchAuthetication } = useAuthenticated();
    const { mutateAsync: logoutMutate } = useLogoutMutationQuery();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data: profile } = useUserProfile();
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false
    });
    const { alert: simpleAlert } = useSimpleAlert();
    const [userId] = useState<string>('');
    const [provider] = useState<string>('');
    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => {
        const pathname = location.pathname;
        const paths = pathname.split('/');

        // 배열의 첫 번째 요소는 빈 문자열이므로, 두 번째 요소가 첫 번째 path임
        const firstPath = paths[1];
        setCurrentPath(firstPath);
    }, [location.pathname]);

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const logout = useCallback(async () => {
        await logoutMutate();
        await refetchAuthetication();
        simpleAlert(t('names:notification'), t('names:successLogout'), 'info');
        navigate('/');
        onClose();
    }, []);

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '210px' }}
            p={0.3}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box height="75px"></Box>
            <Divider />
            {authenticated && userId && provider && (
                <>
                    <List>
                        <ListItem disablePadding>
                            <Box width="100%" display="flex" justifyContent="center">
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    width="90%"
                                    height="100%"
                                    alignItems="center"
                                    pr={1}
                                    mt={1}
                                    mb={1}
                                >
                                    <Chip
                                        label={
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                {provider && provider.includes('google') && (
                                                    <img src="google_icon.png" width="18px" height="18px" />
                                                )}
                                                <Typography
                                                    sx={{
                                                        ml: 1,
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 1,
                                                        letterSpacing: '-0.4px'
                                                    }}
                                                >
                                                    {profile?.name}
                                                </Typography>
                                            </Box>
                                        }
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        </ListItem>
                    </List>
                    <Divider />
                </>
            )}
            <List>
                {!authenticated && (
                    <>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    navigate('/login');
                                    onClose();
                                }}
                            >
                                <ListItemIcon>
                                    <InputIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography fontFamily="Pretendard" fontSize="16px" fontWeight={600}>
                                            {t('names:login')}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    navigate('/signup');
                                    onClose();
                                }}
                            >
                                <ListItemIcon>
                                    <AppRegistrationIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography fontFamily="Pretendard" fontSize="16px" fontWeight={600}>
                                            {t('names:signup')}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    </>
                )}

                {authenticated && (
                    <>
                        {authenticated && profile?.level === 'admin' && (
                            <>
                                <ListItem disablePadding>
                                    <SelectableListItem
                                        icon={<SpaceDashboardIcon />}
                                        label={t('names:businessSite')}
                                        value="asset"
                                        selectedValue={currentPath}
                                        onClick={() => {
                                            navigate('/asset');
                                            onClose();
                                        }}
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <SelectableListItem
                                        icon={<EventIcon />}
                                        label={t('names:event')}
                                        value="event"
                                        selectedValue={currentPath}
                                        onClick={() => {
                                            navigate('/event');
                                            onClose();
                                        }}
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <SelectableListItem
                                        icon={<ArchiveIcon />}
                                        label={t('names:archive')}
                                        value="archives"
                                        selectedValue={currentPath}
                                        onClick={() => {
                                            navigate('/archives');
                                            onClose();
                                        }}
                                    />
                                </ListItem>
                            </>
                        )}
                        {authenticated && profile?.level === 'admin' && (
                            <ListItem disablePadding>
                                <SelectableListItem
                                    icon={<PersonIcon />}
                                    label={t('names:user')}
                                    value="users"
                                    selectedValue={currentPath}
                                    onClick={() => {
                                        navigate('/users');
                                        onClose();
                                    }}
                                />
                            </ListItem>
                        )}
                        {authenticated && profile?.level === 'admin' && (
                            <ListItem disablePadding>
                                <SelectableListItem
                                    icon={<AccessibilityIcon />}
                                    label={t('names:membership')}
                                    value="membership"
                                    selectedValue={currentPath}
                                    onClick={() => {
                                        navigate('/memberships');
                                        onClose();
                                    }}
                                />
                            </ListItem>
                        )}
                        {authenticated && profile?.level === 'admin' && (
                            <ListItem disablePadding>
                                <SelectableListItem
                                    icon={<AddCardIcon />}
                                    label={t('names:mintnft')}
                                    value="membership"
                                    selectedValue={currentPath}
                                    onClick={() => {
                                        navigate('/nftmembership');
                                        onClose();
                                    }}
                                />
                            </ListItem>
                        )}
                        {authenticated && profile?.level === 'admin' && (
                            <ListItem disablePadding>
                                <SelectableListItem
                                    icon={<CreditCardIcon />}
                                    label={t('names:buyingList')}
                                    value="payments"
                                    selectedValue={currentPath}
                                    onClick={() => {
                                        navigate('/payments');
                                        onClose();
                                    }}
                                />
                            </ListItem>
                        )}
                        <ListItem disablePadding>
                            <SelectableListItem
                                icon={<AvTimerIcon />}
                                label={t('names:reserveList')}
                                value="reserves"
                                selectedValue={currentPath}
                                onClick={() => {
                                    navigate('/reserves');
                                    onClose();
                                }}
                            />
                        </ListItem>
                    </>
                )}
            </List>
            <Divider />
            <List>
                <ListItem disablePadding>
                    <SelectableListItem
                        icon={<PersonIcon />}
                        label={t('names:myProfile')}
                        value="profile"
                        selectedValue={currentPath}
                        onClick={() => {
                            onClose();
                            navigate('/profile');
                        }}
                    />
                </ListItem>
                {authenticated && profile?.level === 'admin' && (
                    <>
                        <ListItem disablePadding>
                            <SelectableListItem
                                icon={<PersonAddIcon />}
                                label={t('names:addUser')}
                                value="signup"
                                selectedValue={currentPath}
                                onClick={() => {
                                    onClose();
                                    navigate('/signup');
                                }}
                            />
                        </ListItem>
                        <ListItem disablePadding>
                            <SelectableListItem
                                icon={<SettingsIcon />}
                                label={t('names:systemConfig')}
                                value="sysset"
                                selectedValue={currentPath}
                                onClick={() => {
                                    onClose();
                                    navigate('/sysset');
                                }}
                            />
                        </ListItem>
                    </>
                )}
            </List>
        </Box>
    );

    return (
        <>
            <Drawer
                variant="temporary"
                open={open}
                onClose={onClose}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '210px' }
                }}
            >
                {list('right')}
            </Drawer>
            <Drawer
                variant="permanent"
                open
                onClose={onClose}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '210px' }
                }}
            >
                {list('right')}
            </Drawer>
        </>
    );
};
