import React from 'react';
import { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export const SmallTextField: FC<TextFieldProps> = (prop) => {
    return (
        <TextField
            disabled={prop.disabled}
            sx={prop.sx}
            fullWidth={prop.fullWidth}
            label={prop.label}
            variant="outlined"
            value={prop.value || ''}
            size="small"
            multiline={prop.multiline}
            InputLabelProps={{
                sx: { fontSize: '14px' }
            }}
            type={prop.type}
            rows={prop.rows}
            InputProps={{
                sx: {
                    height: prop.multiline ? 'auto' : '35px',
                    fontSize: '12px'
                },
                endAdornment: prop.InputProps && prop.InputProps.endAdornment ? prop.InputProps.endAdornment : <></>
            }}
            onChange={prop.onChange}
        />
    );
};
