import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { APIConfig } from 'src/apis/config';
import { APIError } from 'src/apis/error';
import { HttpStatusCode } from 'axios';
import { Config } from 'src/apis/type';

export const useAddConfigMutationQuery = () => {
    return useMutation({
        mutationKey: ['@config/add'],
        mutationFn: async (confing: Config) => {
            try {
                const result = await APIConfig.add(confing);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useUpdateConfigMutationQuery = () => {
    return useMutation({
        mutationKey: ['@config/update'],
        mutationFn: async (confing: Config) => {
            try {
                const result = await APIConfig.update(confing);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useConfigsQuery = () => {
    return useQuery({
        queryKey: ['@config'],
        queryFn: async () => {
            try {
                const result = await APIConfig.get();
                if (result.status === HttpStatusCode.Ok) {
                    return result.data as Config;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        },
    });
}