import axios, { AxiosRequestConfig } from 'axios';
import { ENVIRONMENT } from '../env';
import { User, UserType, UserAdminLogin, KakaoInfo } from './type';
import { LoginProviderType, LoginProvider } from '@haechi-labs/face-types';

export class APIReserves {
    static async get(
        search?: string,
        asset?: string,
        startDate?: string,
        endDate?: string,
        page?: number,
        limit?: number
    ) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/reserve`,
            method: 'GET',
            params: {
                search,
                asset,
                startDate,
                endDate,
                page,
                limit
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getCount(search?: string, asset?: string, startDate?: string, endDate?: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/reserve/count`,
            method: 'GET',
            params: {
                search,
                asset,
                startDate,
                endDate
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static updateConfirmed(reserveId: string, confirmed: boolean) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/reserve/confirm`,
            method: 'PUT',
            data: {
                id: reserveId,
                confirmed
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static updateCanceled(reserveId: string, canceled: boolean) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/reserve/cancel`,
            method: 'PUT',
            data: {
                id: reserveId,
                canceled
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }
}
