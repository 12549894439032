import axios, { AxiosRequestConfig } from 'axios';
import { ENVIRONMENT } from '../env';

export class APIMembership {
    static register(form: FormData) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/membership`,
            method: 'POST',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static update(form: FormData) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/membership`,
            method: 'PUT',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static updateAvailable(id: string, isAvailable: boolean) {
        console.log(id, isAvailable);
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/membership/available`,
            method: 'PUT',
            data: {
                id,
                isAvailable
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async get() {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/membership`,
            method: 'GET',
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getById(code: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/membership`,
            method: 'GET',
            withCredentials: true,
            params: {
                code
            }
        };
        const result = axios(config);
        return result;
    }

    // id is _id
    static async delete(id: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/membership`,
            method: 'DELETE',
            data: {
                id
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }
}
