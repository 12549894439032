import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, MenuItem, Menu, IconButton, Button } from '@mui/material';
import { usePaymentListStore } from 'src/store/payment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePaymentMutationQuery } from 'src/apis/hook/payment';
import { useSimpleAlert } from 'src/store/ui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Payment } from 'src/apis/type';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { PaymentDetailDialog } from 'src/page/components/PaymentDetailDialog';

export const PaymentList: FC = () => {
    const matches = useMediaQuery('(min-width:700px)');
    const { page, limit, search, setSearch, setLimit, setPage, startDate, endDate } = usePaymentListStore();
    const { data: payments } = usePaymentMutationQuery(search, startDate, endDate, page, limit);
    const [selectedPayment, setSelectedPayment] = useState<Payment>();
    const { alert: simpleAlert } = useSimpleAlert();

    const navigator = useNavigate();
    const { t } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: '생성일',
            flex: 2,
            minWidth: 50,
            renderCell: (params) => {
                return (
                    <Box minHeight="52px" width="100%" display="flex" alignItems="center">
                        {new Date(params.row.createdAt).toLocaleDateString()}
                    </Box>
                );
            }
        },
        {
            field: '싱품코드',
            headerName: 'ID',
            flex: 3,
            renderCell: (params) => {
                return (
                    <Box
                        width="100%"
                        minHeight="52px"
                        display="flex"
                        alignItems="center"
                        sx={{ textWrap: 'wrap', wordBreak: 'break-all' }}
                    >
                        {params.row.orderId.substring(20)}
                    </Box>
                );
            }
        },

        {
            field: 'result',
            headerName: t('names:status'),
            flex: 3,
            renderCell: (params) => {
                return (
                    <Box minHeight="52px" display="flex" alignItems="center">
                        {params.row.result == 'success' ? 'success' : 'error'}
                    </Box>
                );
            }
        },
        {
            field: 'amount',
            headerName: t('names:price'),
            flex: 3,
            renderCell: (params) => {
                return (
                    <Box minHeight="52px" display="flex" alignItems="center">
                        {params.row.amount}
                    </Box>
                );
            }
        },
        {
            field: 'userId',
            headerName: t('names:userId'),
            flex: 3,
            renderCell: (params) => {
                return <Box minHeight="52px">{params.row.userId}</Box>;
            }
        },
        {
            field: 'control',
            headerName: '',
            flex: 1,
            renderCell: (params) => {
                const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
                const open = Boolean(anchorEl);

                const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget);
                };

                const handleClose = () => {
                    setAnchorEl(null);
                };
                return (
                    <>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                        >
                            <MenuItem
                                onClick={async () => {
                                    setSelectedPayment(params.row);
                                }}
                            >
                                <Button startIcon={<ListAltIcon />}>{t('names:seeDetail')}</Button>
                            </MenuItem>
                        </Menu>
                        <IconButton onClick={handleClick} sx={{ p: 0 }}>
                            <MoreVertIcon />
                        </IconButton>
                    </>
                );
            }
        }
    ];

    const [columnsAdapted, setColumnsAdapted] = useState<GridColDef[]>([]);

    useEffect(() => {
        if (!matches) {
            const newArray = [...columns];
            newArray.pop();
            if (newArray && newArray.length > 0) {
                setColumnsAdapted(newArray);
            }
        } else {
            setColumnsAdapted(columns);
        }
    }, [matches]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {selectedPayment && (
                <PaymentDetailDialog
                    payment={selectedPayment}
                    open={selectedPayment !== undefined}
                    onClose={() => {
                        setSelectedPayment(undefined);
                    }}
                />
            )}
            {payments && payments.length > 0 && (
                <DataGrid
                    rows={payments}
                    onRowClick={(row) => {
                        console.log(row.row);
                    }}
                    getRowHeight={() => (matches ? 52 : 'auto')}
                    columns={columnsAdapted}
                    disableColumnMenu
                    getRowId={(row) => row._id}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: t('names:rowsPerPage')
                        }
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 }
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                brokerId: false,
                                status: false
                            }
                        }
                    }}
                    onPaginationModelChange={(pagination) => {
                        setLimit(pagination.pageSize);
                        setPage(pagination.page);
                    }}
                    pageSizeOptions={[5, 10]}
                />
            )}
        </div>
    );
};
