import React from 'react';
import { Box, Typography } from '@mui/material';
import { AssetsList } from './AssetsList';
import { HeaderAddActionButton } from 'src/page/components/HeaderAddActionButton';
import { useTranslation } from 'react-i18next';

const AssetsContainer = () => {
    const { t } = useTranslation();

    return (
        <Box
            width="100%"
            height="calc(100svh - 75px)"
            sx={{
                overflowY: 'scroll',
                scrollbarWidth: 'none', // Hide the scrollbar for Firefox
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none' // Hide the scrollbar for IE
                }
            }}
            display="flex"
            flexDirection="column"
        >
            <Box width="100%" p={1} display="flex" alignItems="center">
                <Typography fontSize="20px" fontWeight={700}>
                    {t('names:businessSiteList')}
                </Typography>
                <HeaderAddActionButton mode="iconButton" routerPath="/asset/add" labelName={t('names:addAssets')} />
            </Box>

            <Box width="100%" p={1} minHeight="300px" display="flex">
                <AssetsList />
            </Box>
        </Box>
    );
};

export default AssetsContainer;
