import React from 'react';
import { FC, useState } from 'react';
import { Typography, Box, Popper, Button, IconButton, Dialog } from '@mui/material';
import { colors } from 'src/theme/colors';
import { Payment } from 'src/apis/type';
import { useTranslation } from 'react-i18next';
import { useGetUser } from 'src/apis/hook/user';
import { useGetMembershByIdQuery } from 'src/apis/hook/membership';

interface PaymentDetailDialogProps {
    open: boolean;
    payment: Payment;
    onClose?: () => void;
}

export const PaymentDetailDialog: FC<PaymentDetailDialogProps> = ({ open, onClose, payment }) => {
    const { t } = useTranslation();
    const { data: user } = useGetUser(payment ? payment.userId : null);
    const { data: membership } = useGetMembershByIdQuery(payment ? payment.orderId.substring(20) : null);

    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        minWidth: '320px',
                        maxWidth: '740px' // Set your width here
                    }
                }
            }}
            PaperProps={{
                sx: {
                    borderRadius: '10px',
                    background: colors.info
                }
            }}
        >
            <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center">
                <Box display="flex" justifyContent="right" mb={1} p={1}>
                    <Button
                        onClick={() => {
                            if (onClose) {
                                onClose();
                            }
                        }}
                        variant="contained"
                    >
                        {t('names:close')}
                    </Button>
                </Box>
                <Box
                    style={{ height: '100%', width: '100%', overflow: 'scroll' }}
                    flexDirection="column"
                    display="flex"
                    p={1}
                >
                    <Box>
                        <Typography fontSize="16px">
                            {t('names:date')} :{' '}
                            {payment.createdAt ? new Date(payment.createdAt).toLocaleDateString() : ''}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography fontSize="16px">
                            {t('names:buyerCode')} : {payment.orderId}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography fontSize="16px">
                            {t('names:membershipCode')} : {payment.orderId.substring(20)}
                        </Typography>
                    </Box>
                    {membership && (
                        <>
                            {' '}
                            <Box>
                                <Typography fontSize="16px">
                                    {t('names:membershipCode')} : {membership.title}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontSize="16px">
                                    {t('names:membershipCode')} : {membership.desc}
                                </Typography>
                            </Box>
                        </>
                    )}

                    <Box>
                        <Typography fontSize="16px">
                            {t('names:buyerId')} : {payment.userId}
                        </Typography>
                    </Box>
                    {user && (
                        <>
                            <Box>
                                <Typography fontSize="16px">
                                    {t('names:buyerName')} : {user.name}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontSize="16px">
                                    {t('names:buyerEmail')} : {user.email}
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Dialog>
    );
};
