import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { MemberCard } from './MemberCard';
import { MemberAssetList } from './MemberAssetList';

export const ProfileContainer = () => {
    return (
        <Box width="100%" height="calc(100svh - 75px)" flexDirection={'column'} display="flex">
            <Box display="flex" flexDirection="column">
                <MemberCard />
            </Box>
            <Box>
                <MemberAssetList />
            </Box>
        </Box>
    );
};

export default ProfileContainer;
