import axios, { AxiosRequestConfig } from 'axios';
import { ENVIRONMENT } from '../env';
import { User, UserType, UserAdminLogin, KakaoInfo } from './type';
import { LoginProviderType, LoginProvider } from '@haechi-labs/face-types';

export class APIPayment {
    static async get(search?: string, startDate?: string, endDate?: string, page?: number, limit?: number) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/payment`,
            method: 'GET',
            params: {
                search,
                startDate,
                endDate,
                page,
                limit
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }
}
