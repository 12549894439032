import React from 'react';
import { useState } from 'react';
import { Box, IconButton, Typography, Chip, Tab, Tabs, Grid } from '@mui/material';
import { UpdateAssetEditor } from './UpdateAssetEditor';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAssetsUI } from 'src/store/ui';
import { TabMode } from '../types';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const AssetEditorContainer = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const { selectedAsset } = useAssetsUI();
    const queryTab = searchParams.get('tab');
    const [tabMode, setTabMode] = useState<TabMode>(TabMode.MAIN_INFO);

    useEffect(() => {
        if (queryTab) {
            setTabMode(queryTab as TabMode);
        } else {
            setTabMode(TabMode.MAIN_INFO);
        }
    }, [queryTab]);
    return (
        <Box
            width="100%"
            height="calc(100svh - 80px)"
            display={'flex'}
            sx={{
                scrollbarWidth: 'none', // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none' // Hide the scrollbar for IE
                }
            }}
        >
            <Box width="100%" height="100%" display="flex" flexDirection="column" overflow="scroll">
                <Box width="100%" mt={1} mb={1}>
                    <Grid container width="100%">
                        <Grid item md={8} xs={12}>
                            <Box width="100%" display="flex" alignItems="center" mt={1} mb={1}>
                                <Box>
                                    <IconButton
                                        onClick={() => {
                                            navigate('/asset');
                                        }}
                                    >
                                        <ArrowBackIcon></ArrowBackIcon>
                                    </IconButton>
                                </Box>
                                <Box ml={1}>
                                    <Typography fontSize="18px" fontWeight={700} color="black">
                                        {`${t('names:businessSite')} ${t('names:detailView')}(${t('names:update')}):`}
                                    </Typography>
                                </Box>
                                <Box ml={2}>
                                    <Chip
                                        label={
                                            <Typography fontSize="13px" fontWeight={700} color="white">
                                                {`${t('names:businessSite')}: ${selectedAsset && selectedAsset.name}`}
                                            </Typography>
                                        }
                                        color="primary"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box width="100%" display="flex" justifyContent={{ md: 'right', xs: 'center' }}>
                                <Tabs
                                    value={tabMode}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label="scrollable force tabs example"
                                    onChange={(e, newValue) => {
                                        navigate(`/asset/update?tab=${newValue}`);
                                    }}
                                >
                                    <Tab label={t('names:mainInfo')} value={TabMode.MAIN_INFO} />
                                    <Tab label={t('names:runningTime')} value={TabMode.RUNNING_TIME} />
                                    <Tab label={t('names:reservedSpace')} value={TabMode.RESERVED_SPACE} />
                                </Tabs>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box width="100%" display="flex" justifyContent="center">
                    <UpdateAssetEditor tabMode={tabMode} />
                </Box>
            </Box>
        </Box>
    );
};

export default AssetEditorContainer;
