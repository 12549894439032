import { create } from 'zustand';
import { Asset, Archive, Room, Event, Reserve, Membership } from 'src/apis/type';

export interface SimpleAlterUIProp {
    show: boolean;
    title: string;
    content: string;
    type: 'info' | 'error' | 'warn';
    onClose: () => void | undefined;
    setShow: (value: boolean) => void;
    alert: (title: string, mesage: string, type: 'info' | 'error' | 'warn', onClose?: () => void) => void;
}
export const useSimpleAlert = create<SimpleAlterUIProp>((set) => ({
    show: false,
    title: '',
    content: '',
    type: 'info',
    onClose: () => {},
    setShow: (value: boolean) => {
        set(() => ({ show: value }));
    },
    alert: (title: string, content: string, type: 'info' | 'error' | 'warn', onClose?: () => void) => {
        set(() => ({
            show: true,
            title,
            content,
            type,
            onClose: onClose ? onClose : () => {}
        }));
    }
}));

export interface MainUI {
    authenticated: boolean;
    setAuthenticated: (value: boolean) => void;
}

export const useMainUI = create<MainUI>((set) => ({
    authenticated: false,
    setAuthenticated: (value: boolean) => {
        set(() => ({ authenticated: value }));
    }
}));

export interface QrCodeEventParam {
    event: Event | Reserve;
    type: 'event' | 'reserve' | undefined;
}

export interface QrCodeCheckUI {
    event?: QrCodeEventParam;
    setEvent: (value: QrCodeEventParam) => void;
}

export const useQrCodeCheckUI = create<QrCodeCheckUI>((set) => ({
    event: undefined,
    setEvent: (value: QrCodeEventParam) => {
        set(() => ({ event: value }));
    }
}));

export interface AssetsUI {
    selectedAsset: Asset | null;
    selectedAssetRoom: Room | null;
    page: number;
    limit: number;
    setPage: (value: number) => void;
    setLimit: (value: number) => void;
    setSelectedAsset: (value: Asset | null) => void;
    setSelectedAssetRoom: (value: Room | null) => void;
}

export const useAssetsUI = create<AssetsUI>((set) => ({
    selectedAsset: null,
    selectedAssetRoom: null,
    page: 0,
    limit: 10,
    setPage: (value: number) => {
        set(() => ({ page: value }));
    },
    setLimit: (value: number) => {
        set(() => ({ limit: value }));
    },
    setSelectedAsset: (value: Asset | null) => {
        set(() => ({ selectedAsset: value }));
    },
    setSelectedAssetRoom(value) {
        set(() => ({ selectedAssetRoom: value }));
    }
}));

export interface ArchivesUI {
    selectedArchive: Archive | null;
    page: number;
    limit: number;
    setPage: (value: number) => void;
    setLimit: (value: number) => void;
    setSelectedArchive: (value: Archive | null) => void;
}

export const useArchivesUI = create<ArchivesUI>((set) => ({
    selectedArchive: null,
    page: 0,
    limit: 10,
    setPage: (value: number) => {
        set(() => ({ page: value }));
    },
    setLimit: (value: number) => {
        set(() => ({ limit: value }));
    },
    setSelectedArchive: (value: Archive | null) => {
        set(() => ({ selectedArchive: value }));
    }
}));

export interface EventsUI {
    selectedEvent: Event | null;
    page: number;
    limit: number;
    setPage: (value: number) => void;
    setLimit: (value: number) => void;
    setSelectedEvent: (value: Event | null) => void;
}

export const useEventUI = create<EventsUI>((set) => ({
    selectedEvent: null,
    page: 0,
    limit: 5,
    setPage: (value: number) => {
        set(() => ({ page: value }));
    },
    setLimit: (value: number) => {
        set(() => ({ limit: value }));
    },
    setSelectedEvent: (value: Event | null) => {
        set(() => ({ selectedEvent: value }));
    }
}));

export interface MembershipsUI {
    selectedMembership: Membership | null;
    page: number;
    limit: number;
    setPage: (value: number) => void;
    setLimit: (value: number) => void;
    setSelectedMembership: (value: Membership | null) => void;
}

export const useMembershipUI = create<MembershipsUI>((set) => ({
    selectedMembership: null,
    page: 0,
    limit: 10,
    setPage: (value: number) => {
        set(() => ({ page: value }));
    },
    setLimit: (value: number) => {
        set(() => ({ limit: value }));
    },
    setSelectedMembership: (value: Membership | null) => {
        set(() => ({ selectedMembership: value }));
    }
}));
