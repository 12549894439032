import * as React from 'react';
import { FC, useState, FormEvent, useEffect } from 'react';
import {
    FormGroup,
    Box,
    Checkbox,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Typography
} from '@mui/material';
import { AssetBusinessHour } from 'src/apis/type';
import { useTranslation } from 'react-i18next';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

export interface BusinessRunningProps {
    open: boolean;
    onOk?: (runnings: AssetBusinessHour) => void;
    onClose?: () => void;
}

function convertMinutesToTime(minutes: number) {
    const time = dayjs()
        .set('hour', minutes / 60)
        .set('minute', minutes % 60);
    return time;
}

export const BusinessRunningDialog: FC<BusinessRunningProps> = ({ onOk, open, onClose }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };
    const { t } = useTranslation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [personInCharge, setPersonInCharge] = useState('');
    const [dayOff, setDayOff] = useState(false);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [date, setDate] = useState(new Date());

    const handleOk = () => {
        if (onOk) {
            onOk({
                name,
                desc: description,
                date,
                inCharge: personInCharge,
                isRunning: !dayOff,
                startTime,
                endTime: endTime == 0 ? 24 * 60 : endTime
            });
            // reset
            setName('');
            setDescription('');
            setPersonInCharge('');
            setDayOff(false);
            setStartTime(0);
            setEndTime(0);
        }
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form'
                }}
            >
                <DialogTitle>{t('names:addRunningTime')}</DialogTitle>
                <DialogContent>
                    <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                value={dayjs(date)}
                                onChange={(value) => {
                                    setDate(value.toDate());
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={dayOff}
                                        onChange={(__, check) => {
                                            setDayOff(check);
                                        }}
                                    />
                                }
                                label={t('names:dayOff')}
                            />
                        </FormGroup>
                    </Box>

                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        label={t('names:name')}
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        required
                        margin="dense"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                        label={t('names:description')}
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        required
                        margin="dense"
                        value={personInCharge}
                        onChange={(e) => {
                            setPersonInCharge(e.target.value);
                        }}
                        label={t('names:personInCharge')}
                        fullWidth
                        variant="standard"
                    />
                    <Box mt={1}>
                        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center">
                            <Box mb={1}>
                                <Typography>{t('names:runningTime')}</Typography>
                            </Box>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                                        <TimePicker
                                            sx={{ width: '100%' }}
                                            label={t('names:startTime')}
                                            onChange={(date) => {
                                                if (date) {
                                                    const hour = date.get('hour');
                                                    const minute = date.get('minute');
                                                    const totalMinutes = hour * 60 + minute;
                                                    setStartTime(totalMinutes);
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                                        <TimePicker
                                            sx={{ width: '100%' }}
                                            label={t('names:endTime')}
                                            onChange={(date) => {
                                                if (date) {
                                                    const hour = date.get('hour');
                                                    const minute = date.get('minute');
                                                    let totalMinutes = hour * 60 + minute;
                                                    if (totalMinutes == 0) {
                                                        totalMinutes = 24 * 60;
                                                    }
                                                    setEndTime(totalMinutes);
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        onClick={() => {
                            handleOk();
                        }}
                    >
                        {t('names:add')}
                    </Button>
                    <Button onClick={handleClose}>{t('names:cancel')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
