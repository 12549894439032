import axios, { AxiosRequestConfig } from 'axios';
import { ENVIRONMENT } from '../env';
import { Config } from './type';

/**
 * APIUser: User 및 Auth 관련 API
 */

export class APIConfig {
    static async add(config: Config) {
        const reqConfig: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/admin/config`,
            method: 'POST',
            data: {
                ...config
            },
            withCredentials: true
        };
        const result = axios(reqConfig);
        return result;
    }

    static async update(config: Config) {
        const reqConfig: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/admin/config`,
            method: 'PUT',
            data: {
                ...config
            },
            withCredentials: true,
        };
        const result = axios(reqConfig);
        return result;
    }

    static async get() {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/admin/config`,
            method: 'GET',
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }
}
