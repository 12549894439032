import axios, { AxiosRequestConfig } from 'axios';
import { ENVIRONMENT } from '../env';
import { User, UserType, UserAdminLogin, KakaoInfo } from './type';
import { LoginProviderType, LoginProvider } from '@haechi-labs/face-types';

/**
 * APIUser: User 및 Auth 관련 API
 */

export const typeFaceWalletProviderAdapter = (type: LoginProviderType) => {
    switch (type) {
        case LoginProvider.Google:
            return UserType.google;
        case LoginProvider.Facebook:
            return UserType.facebook;
        case LoginProvider.Kakao:
            return UserType.kakao;
        default:
            return null;
    }
};
export class APIUser {
    static async registerByAdmin(user: User) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/admin/user`,
            method: 'POST',
            data: {
                ...user
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async login(loginInfo: UserAdminLogin) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/admin/user/login`,
            method: 'POST',
            data: {
                ...loginInfo
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async logout() {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/admin/user/logout`,
            method: 'POST',
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async refresh() {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/user/refresh`,
            method: 'GET',
            withCredentials: true,
        };
        const result = axios(config);
        return result;
    }

    static async validate() {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/admin/validate`,
            method: 'GET',
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getProfile() {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/user/profile`,
            method: 'GET',
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async kakaoInfoUpdate(kakaoInfo: KakaoInfo) {
        const config: AxiosRequestConfig = {
            method: 'PUT',
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/user/kakao`,
            data: {
                ...kakaoInfo
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }

    static async getUsers(search: string, page: number, limit: number) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/user`,
            method: 'GET',
            withCredentials: true,
            params: {
                search,
                limit,
                page
            }
        };
        const result = axios(config);
        return result;
    }

    static async getUser(id: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/user`,
            method: 'GET',
            withCredentials: true,
            params: {
                id
            }
        };
        const result = axios(config);
        return result;
    }

    static async getUsersCount(search: string) {
        const config: AxiosRequestConfig = {
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/user/count`,
            method: 'GET',
            withCredentials: true,
            params: {
                search
            }
        };
        const result = axios(config);
        return result;
    }

    static async getUserQrCodeValidation(eventId: string, code: string, type: string) {
        const config: AxiosRequestConfig = {
            method: 'POST',
            url: `${ENVIRONMENT.API_ENDPOINT}/auth/admin/validate/qrcode/all`,
            data: {
                eventId,
                code,
                type
            },
            withCredentials: true
        };
        const result = axios(config);
        return result;
    }
}
