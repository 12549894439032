import React from 'react';
import { Box, Typography } from '@mui/material';
import { EventsList } from './EventsList';
import { Actions } from './Actions';
import { useTranslation } from 'react-i18next';
import { HeaderAddActionButton } from 'src/page/components/HeaderAddActionButton';
import { EventController } from './EventController';
const EventsContainer = () => {
    const { t } = useTranslation();
    return (
        <Box
            width="100%"
            height="calc(100svh - 75px)"
            sx={{
                overflowY: 'scroll',
                scrollbarWidth: 'none', // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none' // Hide the scrollbar for IE
                }
            }}
            display="flex"
            flexDirection={'column'}
        >
            <Box width="100%" p={1} display="flex" alignItems="center">
                <Typography fontSize="20px" fontWeight={700}>
                    {t('names:eventList')}
                </Typography>
                <HeaderAddActionButton mode="iconButton" routerPath="/event/add" labelName={t('names:addAssets')} />
            </Box>

            <Box width="100%" p={1} minHeight="500px">
                <EventController />
                <EventsList />
            </Box>
        </Box>
    );
};

export default EventsContainer;
