import React from 'react';
import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Scanner } from '@yudiel/react-qr-scanner';
import { useQrCodeValidation } from 'src/apis/hook/user';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { User, Event } from 'src/apis/type';
import { useSearchParams } from 'react-router-dom';
import { useQrCodeCheckUI } from 'src/store/ui';
import { convertMinutesToTimeFromatString } from 'src/apis/ui';

export const EventQrCheckContainer = ({}) => {
    const [code, setCode] = useState('');
    const [validatedUser, setValidatedUser] = useState<User | undefined>();
    const { mutateAsync: validateAsync } = useQrCodeValidation();
    const [searchParams] = useSearchParams();
    const { event: selectedEvent } = useQrCodeCheckUI();

    useEffect(() => {
        //validate();
    }, [code]);

    return (
        <Box width="100%" height="100%" justifyContent={'center'} display="flex">
            {selectedEvent && selectedEvent.event ? (
                <>
                    <Box display="flex" flexDirection="column" p={1}>
                        {selectedEvent.type == 'event' && (
                            <Box>
                                <Typography fontWeight={900} fontSize="26px">
                                    이벤트 이름:{' '}
                                    {(selectedEvent.event as Event).name ? (selectedEvent.event as Event).name : ''}
                                </Typography>
                            </Box>
                        )}
                        {selectedEvent.type == 'reserve' && (
                            <Box>
                                <Typography fontWeight={900} fontSize="16px">
                                    사업장 이름: {selectedEvent.event.asset ? selectedEvent.event.asset.name : ''}
                                </Typography>
                            </Box>
                        )}

                        <Box display="flex" gap={1}>
                            <Typography fontWeight={900} fontSize="16px">
                                {selectedEvent.event.date ? new Date(selectedEvent.event.date).toLocaleString() : ''}
                            </Typography>
                            <Typography fontWeight={900} fontSize="16px">
                                {selectedEvent.event.startTime
                                    ? convertMinutesToTimeFromatString(selectedEvent.event.startTime)
                                    : ''}
                            </Typography>
                        </Box>
                        <Box width="100%" alignItems="left" display="flex" flexDirection="column" p={1} mt={1}>
                            <Scanner
                                allowMultiple={true}
                                scanDelay={1500}
                                onScan={async (result) => {
                                    if (result && result.length > 0) {
                                        setCode(result[0].rawValue);
                                        if (result[0].rawValue && selectedEvent.event._id && selectedEvent.type) {
                                            try {
                                                const vresult = await validateAsync({
                                                    eventId: selectedEvent.event._id,
                                                    code: result[0].rawValue,
                                                    type: selectedEvent.type
                                                });
                                                if (vresult) {
                                                    setValidatedUser(vresult);
                                                } else {
                                                    setValidatedUser(undefined);
                                                }
                                            } catch (e) {
                                                setValidatedUser(undefined);
                                            }
                                        }
                                    }
                                }}
                            />
                        </Box>
                        {validatedUser && (
                            <>
                                <Box width="100%" justifyContent="center" display="flex">
                                    <Typography fontWeight={900} fontSize="30px">
                                        {validatedUser.name} 님
                                    </Typography>
                                </Box>
                            </>
                        )}
                        <Box width="100%" display="flex" justifyContent="center">
                            {code && code.length > 0 ? (
                                validatedUser ? (
                                    <CheckCircleOutlineIcon color="info" sx={{ fontSize: 100 }} />
                                ) : (
                                    <CancelIcon color="error" sx={{ fontSize: 100 }} />
                                )
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Box>
                </>
            ) : (
                <Box>
                    <Typography>이벤트 정보가 없습니다.</Typography>
                </Box>
            )}
        </Box>
    );
};

export default EventQrCheckContainer;
