import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import { colors } from 'src/theme/colors';
import { useTranslation } from 'react-i18next';
import { simpleAlert } from '../components/SimpleAlertDialog';
import { useAddConfigMutationQuery, useUpdateConfigMutationQuery, useConfigsQuery } from 'src/apis/hook/config';
import { isValidEmail } from 'src/apis/ui';

function InputLabelText({ text, required = false }: { required?: boolean; text: string }) {
    return (
        <Box display="flex">
            <span style={{ color: 'gray', fontWeight: 700 }}>
                {text} {required && <span style={{ color: 'red' }}>*</span>}
            </span>
        </Box>
    );
}

const SystemSettingsContainer = () => {
    const { t } = useTranslation();

    const { mutateAsync: addConfig } = useAddConfigMutationQuery();
    const { mutateAsync: updateConfig } = useUpdateConfigMutationQuery();
    const { data: config } = useConfigsQuery();
    const [email, setEmail] = useState(config?.notification || '');

    useEffect(() => {
        if (config && config.notification) {
            setEmail(config.notification);
        }
    }, [config]);
    const registerOrUpdateConfig = useCallback(async () => {
        try {
            if (email && email.length > 0 && isValidEmail(email)) {
                if (config && config.notification) {
                    const result = await updateConfig({ _id: config._id, notification: email });
                    if (result) {
                        simpleAlert({
                            title: t('names:success'),
                            content: t('names:updateSuccess'),
                            type: 'info'
                        });
                    } else {
                        simpleAlert({
                            title: t('names:fail'),
                            content: t('names:fail'),
                            type: 'error'
                        });
                    }
                } else {
                    const result = await addConfig({ notification: email });
                    if (result) {
                        simpleAlert({
                            title: t('names:success'),
                            content: t('names:createSuccess'),
                            type: 'info'
                        });
                    } else {
                        simpleAlert({
                            title: t('names:fail'),
                            content: t('names:fail'),
                            type: 'error'
                        });
                    }
                }
            } else {
                simpleAlert({ title: t('names:fail'), content: t('names:fail'), type: 'error' });
            }
        } catch (e) {
            simpleAlert({ title: t('names:fail'), content: t('names:fail'), type: 'error' });
        }
    }, [email]);

    return (
        <>
            <Box
                height="100vh"
                sx={{ overflowY: 'scroll', backgroundColor: 'white' }}
                alignItems={'center'}
                display="flex"
                flexDirection={'column'}
                width="100%"
            >
                <Box sx={{ p: 2, borderRadius: '9px' }}>
                    <Box sx={{ p: 1 }}>
                        <Typography variant="h6" color="black" fontWeight={700}>
                            {t('names:systemConfig')}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ p: 1 }}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:email')}
                        value={email}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ width: '350px' }}
                    />
                </Box>

                <Box mt={2}>
                    <Box mt={2} width="100%" display="flex" justifyContent="center">
                        <Button
                            sx={{
                                textTransform: 'none',
                                width: '95%',
                                borderColor: colors.main.gray02,
                                borderRadius: '8px',
                                '&:focus': {
                                    borderColor: colors.main.gray01
                                }
                            }}
                            variant="contained"
                            onClick={registerOrUpdateConfig}
                        >
                            <Box width={'180px'}>
                                <Typography fontSize="16px" color="white">
                                    {t('names:doApply')}
                                </Typography>
                            </Box>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default SystemSettingsContainer;
