import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { APIAsset } from 'src/apis/asset';
import { APIError } from 'src/apis/error';
import { HttpStatusCode } from 'axios';
import { AssetBusinessHours, Assets, AssetBusinessHour, Rooms, AssetNames } from 'src/apis/type';

export const useRegisterAssetMutationQuery = () => {
    return useMutation({
        mutationKey: ['@asset/mutation.register'],
        mutationFn: async (params: FormData) => {
            try {
                const result = await APIAsset.registerAsset(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useRegisterAssetRoomMutationQuery = () => {
    return useMutation({
        mutationKey: ['@asset/room/mutation.register'],
        mutationFn: async (params: FormData) => {
            try {
                const result = await APIAsset.registerAssetRoom(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useUpdateAssetRoomMutationQuery = () => {
    return useMutation({
        mutationKey: ['@asset/room/mutation.update'],
        mutationFn: async (params: FormData) => {
            try {
                const result = await APIAsset.updateAssetRoom(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useUpdateAssetMutationQuery = () => {
    return useMutation({
        mutationKey: ['@asset/mutation.update'],
        mutationFn: async (params: FormData) => {
            try {
                const result = await APIAsset.updateAsset(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useDeleteAssetMutationQuery = () => {
    return useMutation({
        mutationKey: ['@asset/mutation.delete'],
        mutationFn: async (id: string) => {
            try {
                const result = await APIAsset.deleteAsset(id);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};
export const useDeleteRoomMutationQuery = () => {
    return useMutation({
        mutationKey: ['@asset/room/mutation.delete'],
        mutationFn: async ({ asset, room }: { asset: string, room: string }) => {
            try {
                const result = await APIAsset.deleteRoom(asset, room);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useAssetRoomsQuery = (id?: string) => {
    return useQuery({
        queryKey: ['@asset/authenticated', id],
        queryFn: async () => {
            try {
                if (id === undefined) return [];
                const result = await APIAsset.getAssetRooms(id);
                if (result.status == 200) {
                    return result.data as Rooms;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 60000
    });
};

export const useAssetsQuery = (page: number, limit: number) => {
    return useQuery({
        queryKey: ['@asset/authenticated', page, limit],
        queryFn: async () => {
            try {
                const result = await APIAsset.getAssets(page, limit);
                if (result.status == 200) {
                    return result.data as Assets;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 60000
    });
};

export const useInsertAssetRunningHoursMutationQuery = () => {
    return useMutation({
        mutationKey: ['@asset/runninghours/mutation.inserts'],
        mutationFn: async (param: { id: string; datas: AssetBusinessHours }) => {
            try {
                const result = await APIAsset.insertAssetRunningHours(param.id, param.datas);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useInsertAssetRunningHourMutationQuery = () => {
    return useMutation({
        mutationKey: ['@asset/runninghours/mutation.insert'],
        mutationFn: async (param: { id: string; data: AssetBusinessHour }) => {
            try {
                const result = await APIAsset.insertAssetRunningHour(param.id, param.data);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useAssetsRunningHoursQuery = (id: string | undefined, limit: number = 10, page: number = 0) => {
    return useQuery({
        queryKey: ['@asset/get/authenticated', id, limit, page],
        queryFn: async () => {
            try {
                if (id === undefined) return [];
                const result = await APIAsset.getAssetRunningHours(id, limit, page);
                if (result.status == 200) {
                    return result.data as AssetBusinessHours;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 60000
    });
};

export const useAssetsRunningHoursCountQuery = (id: string | undefined) => {
    return useQuery({
        queryKey: ['@asset/get/authenticated/count', id],
        queryFn: async () => {
            try {
                if (id === undefined) return [];
                const result = await APIAsset.getAssetRunningHoursCount(id);
                if (result.status == 200) {
                    return result.data as number;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 60000
    });
};

export const useAssetNamesQuery = () => {
    return useQuery({
        queryKey: ['@asset/names'],
        queryFn: async () => {
            try {
                const result = await APIAsset.getAssetNames();
                if (result.status == 200) {
                    return result.data as AssetNames;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 60000
    });
};
