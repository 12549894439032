import React from 'react';
import { FC, ReactNode, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Grid, IconButton, Avatar, Badge, MenuItem, Menu, Button, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DrawerMenu } from './DrawerMenu';
import { colors } from 'src/theme/colors';
import { useTranslation } from 'react-i18next';
import { useAuthenticated, useLogoutMutationQuery, useUserProfile } from 'src/apis/hook/user';
import { useSimpleAlert } from 'src/store/ui';
import MenuIcon from '@mui/icons-material/Menu';
import './image.css';

interface HeaderLayoutProps {
    children?: ReactNode;
    showFooter?: boolean;
    showMenu?: boolean;
    titleLink?: string;
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    backgroundColor: 'white',
    border: `2px solid ${theme.palette.background.paper}`
}));

const HeaderLayout: FC<HeaderLayoutProps> = ({ children, showFooter, showMenu, titleLink }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { mutateAsync: logoutMutate } = useLogoutMutationQuery();
    const { data: authenticated, refetch: refetchAuthetication } = useAuthenticated();
    const [anchorProfileMenuEl, setAnchorProfileMenuEl] = React.useState<null | HTMLElement>(null);
    const openProfileMenu = Boolean(anchorProfileMenuEl);
    const { data: profile } = useUserProfile();
    const [openMenu, setOpenMenu] = useState(false);
    const { alert: simpleAlert } = useSimpleAlert();

    const handleClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorProfileMenuEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorProfileMenuEl(null);
    };

    const logout = useCallback(async () => {
        if (authenticated) {
            await logoutMutate();
            await refetchAuthetication();
            simpleAlert(t('names:notification'), t('names:successLogout'), 'info');
            navigate('/');
        }
    }, [authenticated]);

    return (
        <>
            <Menu
                id="basic-menu"
                anchorEl={anchorProfileMenuEl}
                open={openProfileMenu}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem
                    onClick={() => {
                        navigate('/profile');
                    }}
                >
                    {t('names:myAccount')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        logout();
                        handleClose();
                    }}
                >
                    {t('names:logout')}
                </MenuItem>
            </Menu>
            <Box width="100%" height="100vsh" overflow="hidden">
                <Grid
                    container
                    display="flex"
                    height="75px"
                    position="static"
                    sx={{ width: { md: `calc(100% - ${220}px)` }, ml: { md: `${220}px` } }}
                >
                    {authenticated ? (
                        <Grid xs={6} sm={6} md={6} lg={6}>
                            <Box display="flex" alignItems="center" height="100%" width="100%">
                                <Box sx={{ display: { md: 'none' } }}>
                                    <Box height="100%" alignItems="center" display="flex">
                                        {showMenu && (
                                            <IconButton
                                                onClick={() => {
                                                    setOpenMenu(!openMenu);
                                                }}
                                            >
                                                <MenuIcon style={{ height: '35px', width: '35px', color: 'black' }} />
                                            </IconButton>
                                        )}
                                    </Box>
                                </Box>
                                <Button
                                    color="success"
                                    onClick={handleClickProfile}
                                    variant="contained"
                                    sx={{ height: '35px' }}
                                >
                                    <Typography
                                        fontSize="13px"
                                        textTransform={'none'}
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {profile?.email}
                                    </Typography>{' '}
                                </Button>
                            </Box>
                        </Grid>
                    ) : (
                        <Grid xs={6} sm={6} md={6} lg={6}>
                            <Box
                                display="flex"
                                alignItems="center"
                                height="100%"
                                width="100%"
                                justifyContent="right"
                                pr={1}
                            >
                                <Button
                                    onClick={() => {
                                        navigate('/login');
                                    }}
                                    sx={{ fontSize: '16px' }}
                                >
                                    {t('names:login')}
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <Divider />
                <Box width="100%" height={'calc(100svh - 80px)'} display="flex">
                    <Box
                        component="nav"
                        sx={{ width: { md: '210px' }, flexShrink: { sm: 0 } }}
                        aria-label="mailbox folders"
                    >
                        <DrawerMenu open={openMenu} onClose={() => setOpenMenu(false)} />
                    </Box>
                    <Box display="flex" sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${210}px)` } }}>
                        {children || <Outlet />}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

HeaderLayout.propTypes = {
    children: PropTypes.node
};
export default HeaderLayout;
