import React, { FC, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Box, IconButton, MenuItem, Menu } from '@mui/material';
import { useAssetsQuery, useDeleteAssetMutationQuery } from 'src/apis/hook/asset';
import { useAssetsUI, useSimpleAlert } from 'src/store/ui';
import { Asset } from 'src/apis/type';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DateRangeIcon from '@mui/icons-material/DateRange';

export const AssetsList: FC = () => {
    const matches = useMediaQuery('(min-width:700px)');
    const { page, limit, setPage } = useAssetsUI();
    const { data: assets, refetch: refetchAssets } = useAssetsQuery(page, limit);
    const { setSelectedAsset } = useAssetsUI();
    const { mutateAsync: deleteAsset } = useDeleteAssetMutationQuery();
    const { alert: simpleAlert } = useSimpleAlert();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [columnsAdapted, setColumnsAdapted] = useState<GridColDef[]>([]);

    const columns: GridColDef[] = [
        {
            field: 'isAvailable',
            headerName: t('names:activeStatus'),
            flex: 1,
            renderCell: (params) => {
                return params.value ? <CheckCircleIcon /> : <CancelIcon />;
            }
        },
        { field: 'name', headerName: t('names:name'), flex: 2 },
        { field: 'desc', headerName: t('names:description'), flex: 2 },
        { field: 'address', headerName: t('names:address'), flex: 2 },
        {
            field: 'type',
            headerName: '형태',
            flex: 1,
            minWidth: 30,
            renderCell: (params) => {
                return params.value == 'space'
                    ? t('names:businessSite')
                    : params.value == 'tservice'
                    ? t('names:tserviceSite')
                    : t('names:serviceSite');
            }
        },
        {
            field: 'control',
            headerName: '',
            flex: 1,
            renderCell: (params) => {
                const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
                const open = Boolean(anchorEl);

                const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget);
                };

                const handleClose = () => {
                    setAnchorEl(null);
                };

                return (
                    <>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setSelectedAsset(params.row as Asset);
                                    navigate('/asset/update');
                                }}
                            >
                                <Button startIcon={<AutoFixHighIcon />}>{t('names:doUpdate')}</Button>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setSelectedAsset(params.row as Asset);
                                    navigate('/asset/update?tab=rtime');
                                }}
                            >
                                <Button startIcon={<AccessTimeFilledIcon />}>{t('names:dayRunningTime')}</Button>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setSelectedAsset(params.row as Asset);
                                    navigate('/asset/wday');
                                }}
                            >
                                <Button startIcon={<DateRangeIcon />}>{t('names:runningDate')}</Button>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setSelectedAsset(params.row as Asset);
                                    navigate('/asset/room');
                                }}
                            >
                                <Button startIcon={<MeetingRoomIcon />}>{t('names:addRoom')}</Button>
                            </MenuItem>
                            <MenuItem
                                onClick={async () => {
                                    const confirmDelete = confirm('정말 삭제하시겠습니까?');
                                    if (!confirmDelete) return;
                                    const result = await deleteAsset(params.row.id);
                                    if (result) {
                                        simpleAlert(t('names:notification'), t('names:deleteSuccess'), 'info');
                                        refetchAssets();
                                    } else {
                                        simpleAlert(t('names:error'), t('names:deleteFail'), 'error');
                                    }
                                }}
                            >
                                <Button startIcon={<DeleteIcon />}>{t('names:delete')}</Button>
                            </MenuItem>
                        </Menu>
                        <IconButton onClick={handleClick} sx={{ p: 0 }}>
                            <MoreVertIcon />
                        </IconButton>
                    </>
                );
            }
        }
    ];

    useEffect(() => {
        if (!matches) {
            const newArray = columns.slice(0, 1).concat(columns.slice(2));
            setColumnsAdapted(newArray);
        } else {
            setColumnsAdapted(columns);
        }
    }, [matches]);

    return (
        <Box style={{ height: '100%', width: '100%' }}>
            {assets && assets.length > 0 && (
                <DataGrid
                    rows={assets}
                    columns={columnsAdapted}
                    rowSelection={false}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: t('names:rowsPerPage')
                        }
                    }}
                    paginationModel={{ page, pageSize: limit }}
                    onPaginationModelChange={(pagination) => setPage(pagination.page)}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                brokerId: false,
                                status: false
                            }
                        }
                    }}
                />
            )}
        </Box>
    );
};
