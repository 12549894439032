export class APIError extends Error {
    readonly status: number

    // base constructor only accepts string message as an argument
    // we extend it here to accept an object, allowing us to pass other data
    constructor(status: number, name: string, message?: string) {
        super(message)
        this.name = name // this property is defined in parent
        this.status = status
    }
}