import React from 'react';
import { FC } from 'react';
import { Box, IconButton, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAssetsUI } from 'src/store/ui';
import { useTranslation } from 'react-i18next';
import { useAssetRoomsQuery, useDeleteRoomMutationQuery } from 'src/apis/hook/asset';
import { simpleAlert } from 'src/page/components/SimpleAlertDialog';
import { confirmDialog } from 'src/page/components/ConfirmDialog';

export const AssetRooms: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { selectedAsset, setSelectedAssetRoom } = useAssetsUI();
    const { data: rooms, refetch: refetchRooms } = useAssetRoomsQuery(selectedAsset?._id);
    const { mutateAsync: deleteRoom } = useDeleteRoomMutationQuery();
    const columns: GridColDef[] = [
        { field: 'name', headerName: t('names:name'), flex: 1 },
        { field: 'desc', headerName: t('names:description'), flex: 1 },
        {
            field: 'minCapa',
            headerName: t('names:minValue'),
            flex: 1
        },
        {
            field: 'maxCapa',
            headerName: t('names:maxValue'),
            flex: 1
        },
        {
            field: 'detail',
            headerName: '',
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <Button
                        onClick={() => {
                            setSelectedAssetRoom(params.row);
                            navigate('/asset/room/update');
                        }}
                    >
                        {t('names:doUpdate')}
                    </Button>
                );
            }
        },
        {
            field: 'delete',
            headerName: '',
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <Button
                        onClick={async () => {
                            setSelectedAssetRoom(params.row);
                            if (selectedAsset && selectedAsset._id) {
                                const confirmed = await confirmDialog({
                                    title: t('names:confirm'),
                                    content: t('names:deleteRoomConfirm'),
                                    type: 'info'
                                });
                                if (!confirmed) {
                                    return;
                                }
                                try {
                                    const result = await deleteRoom({ asset: selectedAsset._id, room: params.row._id });
                                    if (result) {
                                        refetchRooms();
                                        setSelectedAssetRoom(null);
                                        simpleAlert({
                                            title: t('names:confirm'),
                                            content: t('names:deleteSuccess'),
                                            type: 'info'
                                        });
                                    }
                                } catch (e: any) {
                                    simpleAlert({
                                        title: t('names:error'),
                                        content: t('names:deleteFail'),
                                        type: 'error'
                                    });
                                }
                            } else {
                                simpleAlert({
                                    title: t('names:error'),
                                    content: t('names:notFoundSelectedAsset'),
                                    type: 'error'
                                });
                            }
                        }}
                    >
                        {t('names:delete')}
                    </Button>
                );
            }
        }
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" overflow="scroll" p={1}>
            {rooms && rooms.length > 0 && (
                <DataGrid
                    rows={rooms}
                    columns={columns}
                    hideFooterPagination
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 }
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                brokerId: false,
                                status: false
                            }
                        }
                    }}
                />
            )}
        </Box>
    );
};
