import React from 'react';
import { FC, useState } from 'react';
import { Typography, Box, Popper, Button, IconButton, Dialog } from '@mui/material';
import { colors } from 'src/theme/colors';
import { Reserve } from 'src/apis/type';
import { useTranslation } from 'react-i18next';
import { convertMinutesToTimeFromatString } from 'src/apis/ui';

interface ReserveDetailDialogProps {
    open: boolean;
    reserve: Reserve;
    onClose?: () => void;
}

export const ReserveDetailDialog: FC<ReserveDetailDialogProps> = ({ open, onClose, reserve }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        minWidth: '320px',
                        maxWidth: '740px' // Set your width here
                    }
                }
            }}
            PaperProps={{
                sx: {
                    borderRadius: '10px',
                    background: colors.info
                }
            }}
        >
            <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center">
                <Box display="flex" justifyContent="right" mb={1} p={1}>
                    <Button
                        onClick={() => {
                            if (onClose) {
                                onClose();
                            }
                        }}
                        variant="contained"
                    >
                        {t('names:close')}
                    </Button>
                </Box>
                <Box
                    style={{ height: '100%', width: '100%', overflow: 'scroll' }}
                    flexDirection="column"
                    display="flex"
                    p={1}
                >
                    <Box>
                        <Typography fontSize="16px">
                            {t('names:businessSite')} : {reserve.asset.name}
                        </Typography>
                    </Box>
                    {reserve.room && (
                        <Box>
                            <Typography fontSize="16px">
                                {t('names:roomInfo')} : {reserve.room.name}
                            </Typography>
                        </Box>
                    )}
                    <Box>
                        <Typography fontSize="16px">
                            {t('names:desc')} : {reserve.desc}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography fontSize="16px">
                            {t('names:booker')} : {reserve.user.name}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography fontSize="16px">
                            {t('names:bookerEmail')} : {reserve.user.email}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography fontSize="16px">
                            {t('names:bookerName')} : {reserve.reservedPerson}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography fontSize="16px">
                            {t('names:bookerContact')} : {reserve.reservedContact}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography fontSize="16px">
                            {t('names:date')} : {new Date(reserve.date).toLocaleDateString()}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography fontSize="16px">
                            {t('names:startTime')} : {convertMinutesToTimeFromatString(reserve.startTime)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};
