import React from 'react';
import { Box, Typography } from '@mui/material';
import { PaymentList } from './PaymentList';
import { useTranslation } from 'react-i18next';
import { PaymentController } from './PaymentController';
const PaymentContainer = () => {
    const { t } = useTranslation();
    return (
        <Box
            width="100%"
            height="calc(100svh - 75px)"
            sx={{
                overflowY: 'scroll',
                scrollbarWidth: 'none', // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none' // Hide the scrollbar for IE
                }
            }}
            display="flex"
            flexDirection={'column'}
        >
            <Box width="100%" p={1} display="flex" alignItems="center">
                <Box>
                    <Typography fontSize="20px" fontWeight={700}>
                        {t('names:buyingList')}
                    </Typography>
                </Box>
            </Box>

            <Box width="100%" p={1} minHeight="500px">
                <PaymentController />
                <PaymentList />
            </Box>
        </Box>
    );
};

export default PaymentContainer;
