import React from 'react';
import { FC } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
interface HeaderAddActionButtonProps {
    mode: 'button' | 'iconButton';
    routerPath?: string;
    labelName?: string;
    icon?: React.ReactNode;
    onClick?: () => void;
}
export const HeaderAddActionButton: FC<HeaderAddActionButtonProps> = ({
    icon,
    mode,
    routerPath,
    labelName,
    onClick
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Box p={1} display="flex" alignContent="center">
            <Box>
                {mode == 'button' ? (
                    <Button
                        variant="outlined"
                        onClick={() => {
                            if (routerPath) {
                                navigate(routerPath);
                            }
                            if (onClick) {
                                onClick();
                            }
                        }}
                    >
                        {labelName ? labelName : ''}
                    </Button>
                ) : (
                    <IconButton
                        sx={{ p: 0 }}
                        onClick={() => {
                            if (routerPath) {
                                navigate(routerPath);
                            }
                            if (onClick) {
                                onClick();
                            }
                        }}
                    >
                        {icon ? icon : <AddCircleIcon />}
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};

export default HeaderAddActionButton;
