import React from 'react';
import { FC, useState } from 'react';
import { Box, IconButton, Typography, Chip } from '@mui/material';
import { AddAssetDayRunningEditor } from './AddAssetBusinessRunningEditor';
import { AssetRunnings, AssetBusinessHours } from 'src/apis/type';
import { useAssetsUI } from 'src/store/ui';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export interface AssetRunningContainerProps {
    onChange?: (runnings: AssetRunnings) => void;
    onRunningHourChange?: (bsRunnings: AssetBusinessHours) => void;
}

const AssetBussinessRunningContainer: FC<AssetRunningContainerProps> = ({ onChange, onRunningHourChange }) => {
    const { selectedAsset } = useAssetsUI();
    const { t } = useTranslation();
    const [openRunningDialog, setOpenRunningDialog] = useState(false);
    const navigate = useNavigate();

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" overflow="scroll">
            <Box width="100%" display="flex" alignItems="center" mt={1} mb={1}>
                <Box>
                    <IconButton
                        onClick={() => {
                            navigate('/asset');
                        }}
                    >
                        <ArrowBackIcon></ArrowBackIcon>
                    </IconButton>
                </Box>
                <Box ml={1}>
                    <Typography fontSize="18px" fontWeight={700} color="black">
                        {`${t('names:businessSite')} ${t('names:dayRunningTime')}(${t('names:update')}):`}
                    </Typography>
                </Box>
                <Box ml={2}>
                    <Chip
                        label={
                            <Typography fontSize="13px" fontWeight={700} color="white">
                                {selectedAsset && selectedAsset.name}
                            </Typography>
                        }
                        color="primary"
                    />
                </Box>
            </Box>

            <Box width="100%" display="flex" justifyContent="center">
                <AddAssetDayRunningEditor
                    onChange={onRunningHourChange}
                    onCloseAddDialog={() => {
                        setOpenRunningDialog(false);
                    }}
                />
            </Box>
        </Box>
    );
};

export default AssetBussinessRunningContainer;
