import React from 'react';
import { FC, useState } from 'react';
import { Typography, Box, Popper, Button, IconButton, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'src/theme/colors';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAssetsQuery } from 'src/apis/hook/asset';
import { useAssetsUI } from 'src/store/ui';
import { Asset } from 'src/apis/type';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeleteAssetMutationQuery } from 'src/apis/hook/asset';
import { useSimpleAlert } from 'src/store/ui';
interface AssetSelectDialogProps {
    open: boolean;
    onClose?: () => void;
    onSelect?: (asset: Asset) => void;
}

export const AssetSelectDialog: FC<AssetSelectDialogProps> = ({ open, onSelect, onClose }) => {
    const { t } = useTranslation();
    const { page, limit } = useAssetsUI();
    const { data: asstes, refetch: refetchAssets } = useAssetsQuery(page, limit);
    const { setSelectedAsset } = useAssetsUI();
    const { mutateAsync: deleteAsset } = useDeleteAssetMutationQuery();
    const { alert: simpleAlert } = useSimpleAlert();
    const navigator = useNavigate();
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'name', headerName: t('names:name'), minWidth: 130 },
        {
            field: 'address',
            headerName: t('names:address'),
            minWidth: 180
        },
        { field: 'type', headerName: '형태', minWidth: 130 },
        {
            field: 'detail',
            headerName: '보기',
            minWidth: 50,
            renderCell: (params: any) => {
                //params.value
                return (
                    <Button
                        onClick={() => {
                            setSelectedAsset(params.row as Asset);
                            navigator('/assets/detail');
                        }}
                    >
                        {t('names:preview')}
                    </Button>
                );
            }
        },

        {
            field: 'control',
            headerName: '',
            minWidth: 50,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            onClick={() => {
                                if (onSelect) {
                                    onSelect(params.row as Asset);
                                }
                                if (onClose) {
                                    onClose();
                                }
                            }}
                        >
                            {t('names:select')}
                        </Button>
                    </Box>
                );
            }
        }
    ];
    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '740px' // Set your width here
                    }
                }
            }}
            PaperProps={{
                sx: {
                    height: '500px',
                    borderRadius: '10px',
                    background: colors.info
                }
            }}
        >
            <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center">
                <Box display="flex" justifyContent="right" mb={1} p={1}>
                    <Button
                        onClick={() => {
                            if (onClose) {
                                onClose();
                            }
                        }}
                        variant="contained"
                    >
                        {t('names:close')}
                    </Button>
                </Box>
                <Box style={{ height: '100%', width: '100%', overflow: 'scroll' }} display="flex" p={1}>
                    {asstes && asstes.length > 0 && (
                        <DataGrid
                            rows={asstes}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 }
                                }
                            }}
                            pageSizeOptions={[5, 10]}
                        />
                    )}
                </Box>
            </Box>
        </Dialog>
    );
};
