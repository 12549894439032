import * as React from 'react';
import { FC, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, MenuItem, Menu, IconButton, Box, Chip } from '@mui/material';
import {
    useGetMembershQuery,
    useDeleteMembershipMutationQuery,
    useUpdateMembershipAvailableMutationQuery
} from 'src/apis/hook/membership';
import { useTranslation } from 'react-i18next';
import { useSimpleAlert, useMembershipUI } from 'src/store/ui';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { idID } from '@mui/material/locale';

export const MembershipList: FC = () => {
    const { data: memberships, refetch: refetch } = useGetMembershQuery();
    const { mutateAsync: deleteMember } = useDeleteMembershipMutationQuery();
    const { mutateAsync: updateMemberAvailable } = useUpdateMembershipAvailableMutationQuery();
    const { alert: simpleAlert } = useSimpleAlert();

    const { t } = useTranslation();
    const navigator = useNavigate();
    const { setSelectedMembership } = useMembershipUI();
    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: '생성일',
            flex: 1,
            minWidth: 50,
            renderCell: (params) => {
                return new Date(params.row.createdAt).toLocaleDateString();
            }
        },
        { field: 'title', headerName: t('names:title'), flex: 2, minWidth: 130 },
        { field: 'desc', headerName: t('names:description'), flex: 3, minWidth: 150 },
        { field: 'price', headerName: t('names:price'), flex: 3, minWidth: 150 },
        {
            field: 'statuss',
            headerName: t('names:status'),
            flex: 2,
            minWidth: 50,
            renderCell: (params) => {
                return (
                    <Box>
                        <Chip
                            label={params.row.isAvailable ? t('names:active') : t('names:inactived')}
                            color={params.row.isAvailable ? 'primary' : 'error'}
                        />
                    </Box>
                );
            }
        },
        {
            field: 'control',
            headerName: '',
            flex: 3,
            minWidth: 90,
            renderCell: (params) => {
                const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
                const open = Boolean(anchorEl);

                const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget);
                };

                const handleClose = () => {
                    setAnchorEl(null);
                };
                return (
                    <>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setSelectedMembership(params.row);
                                    navigator('/memberships/update');
                                }}
                            >
                                <Button startIcon={<AutoFixHighIcon />}>{t('names:doUpdate')}</Button>
                            </MenuItem>
                            <MenuItem
                                onClick={async () => {
                                    const confirmDelete = confirm('정말 삭제하시겠습니까?');
                                    if (!confirmDelete) return;
                                    const result = await deleteMember(params.row._id);
                                    if (result) {
                                        simpleAlert(t('names:notification'), t('names:deleteSuccess'), 'info');
                                        refetch();
                                    } else {
                                        simpleAlert(t('names:error'), t('names:deleteFail'), 'error');
                                    }
                                }}
                            >
                                <Button startIcon={<DeleteIcon />}>{t('names:delete')}</Button>
                            </MenuItem>
                            <MenuItem
                                onClick={async () => {
                                    const qustion = `${
                                        params.row.isAvailable ? t('names:inactive') : t('names:active')
                                    } ${t('names:chageActive')}`;
                                    if (confirm(qustion)) {
                                        await updateMemberAvailable({
                                            id: params.row._id,
                                            isAvailable: params.row.isAvailable ? false : true
                                        });
                                        refetch();
                                    }
                                    handleClose();
                                }}
                            >
                                <Button startIcon={<AccessibilityIcon />}>
                                    {params.row.isAvailable ? t('names:inactive') : t('names:active')}
                                </Button>
                            </MenuItem>
                        </Menu>
                        <IconButton onClick={handleClick} sx={{ p: 0 }}>
                            <MoreVertIcon />
                        </IconButton>
                    </>
                );
            }
        }
    ];

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {memberships && memberships.length > 0 && (
                <DataGrid
                    rows={memberships}
                    columns={columns}
                    getRowId={(row) => row._id}
                    disableRowSelectionOnClick
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 }
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                brokerId: false,
                                status: false
                            }
                        }
                    }}
                    pageSizeOptions={[5, 10]}
                />
            )}
        </div>
    );
};
