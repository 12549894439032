import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { APIUser } from 'src/apis/user';
import { APIError } from 'src/apis/error';
import { HttpStatusCode } from 'axios';
import { User, Users, UserAdminLogin, QrCodeValidate, KakakoAccessToken, KakaoInfo, QrCode } from 'src/apis/type';
export const useAddUserMutationQuery = () => {
    return useMutation({
        mutationKey: ['@user/mutation.signup'],
        mutationFn: async (params: User) => {
            try {
                const result = await APIUser.registerByAdmin(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

//mutation
export const useLogoutMutationQuery = () => {
    const qc = useQueryClient();
    return useMutation({
        mutationKey: ['@user/mutation.logout'],
        mutationFn: async () => {
            qc.setQueriesData({ queryKey: ['@user/authenticated'], type: 'active' }, false);
            qc.setQueriesData({ queryKey: ['@user/profile'], type: 'active' }, null);
            await APIUser.logout();
            return true;
        }
    });
};

export const useUserProfile = () => {
    return useQuery<User | null>({
        queryKey: ['@user/profile'],
        queryFn: async () => {
            const result = await APIUser.getProfile();
            if (result.data) {
                return result.data as User;
            } else {
                return null;
            }
        },

        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true
    });
};

export const useGetUsers = (search: string, page: number, limit: number) => {
    return useQuery<Users | null>({
        queryKey: ['@user/userlist', search, page, limit],
        queryFn: async () => {
            const result = await APIUser.getUsers(search, page, limit);
            if (result.data) {
                return result.data as Users;
            } else {
                return null;
            }
        },

        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true
    });
};

export const useRefreshToken = () => {
    return useQuery({
        queryKey: ['@user/refresh'],
        queryFn: async () => {
            try {
                const result = await APIUser.refresh();
                if (result.status == 200) {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                return false;
            }
        },
    });
};

export const useGetUsersCount = (search: string) => {
    return useQuery<number>({
        queryKey: ['@user/userlist/count', search],
        queryFn: async () => {
            const result = await APIUser.getUsersCount(search);
            if (result.data) {
                return result.data as number;
            } else {
                return 0;
            }
        },

        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true
    });
};

export const useLoginMutationQuery = () => {
    return useMutation({
        mutationKey: ['@user/mutation.login'],
        mutationFn: async (params: UserAdminLogin) => {
            try {
                const result = await APIUser.login(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useKakaoUpdateMutationQuery = () => {
    return useMutation({
        mutationKey: ['@user/mutation.kakao/update'],
        mutationFn: async (params: KakaoInfo) => {
            try {
                const result = await APIUser.kakaoInfoUpdate(params);

                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useAuthenticated = () => {
    return useQuery({
        queryKey: ['@user/authenticated'],
        queryFn: async () => {
            try {
                const result = await APIUser.validate();
                if (result.status == 200) {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                return false;
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 600000
    });
};

export const useGetUser = (id?: string | null) => {
    return useQuery({
        queryKey: ['@user/user', id],
        queryFn: async () => {
            try {
                if (id && id.length > 0) {
                    const result = await APIUser.getUser(id);
                    if (result.status == 200) {
                        return result.data as User;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            } catch (e) {
                return null;
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 600000
    });
};

export const useQrCodeValidation = () => {
    return useMutation({
        mutationKey: ['@user/qrcode/validate'],
        mutationFn: async ({ eventId, code, type }: QrCodeValidate) => {
            try {
                const result = await APIUser.getUserQrCodeValidation(eventId, code, type);
                if (result.status === HttpStatusCode.Ok) {
                    return result.data as User;
                } else {
                    return null;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};
