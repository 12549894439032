import React from 'react';
import { Box, IconButton } from '@mui/material';
import { AddArchiveEditor } from './AddArchiveEditor';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
const ArchiveEditorContainer = () => {
    const navigate = useNavigate();
    return (
        <Box
            width="100%"
            height="calc(100svh - 80px)"
            display={'flex'}
            sx={{
                scrollbarWidth: 'none', // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none' // Hide the scrollbar for IE
                }
            }}
        >
            <Box width="100%" height="100%" display="flex" flexDirection="column" overflow="scroll">
                <Box width="100%" height="10px">
                    <IconButton
                        onClick={() => {
                            navigate('/assets');
                        }}
                    >
                        <ArrowBackIcon></ArrowBackIcon>
                    </IconButton>
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                    <AddArchiveEditor />
                </Box>
            </Box>
        </Box>
    );
};

export default ArchiveEditorContainer;
