import React from 'react';
import { Box } from '@mui/material';
const MainContainer = () => {
    return (
        <Box
            width="100%"
            height="calc(100svh - 75px)"
            sx={{
                overflowY: 'scroll',
                scrollbarWidth: 'none', // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none' // Hide the scrollbar for IE
                }
            }}
            justifyContent={'center'}
            display="flex"
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '640px',
                    height: '100%',
                    backgroundSize: 'cover'
                }}
            ></Box>
        </Box>
    );
};

export default MainContainer;
