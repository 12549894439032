import React from 'react';
import { RouteObject } from 'react-router';
import LoginContainer from './page/login/LoginContainer';
import SignUpContainer from './page/signup/SignUpContainer';
import MainContainer from './page/main/MainContainer';
import HeaderLayout from './layout/HeaderLayout';
import ProfileContainer from './page/profile/ProfileContainer';
import AssetsContainer from './page/assets/AssetsContainer';
import AssetAddContainer from './page/assets/add/AssetsEditorContainer';
import AssetDetailContainer from './page/assets/detail/AssetsDetailContainer';
import ArchivesContainer from './page/archives/ArchivesContainer';
import ArchivesEditorContainer from './page/archives/add/ArchivesEditorContainer';
import AssetBussinessRunningContainer from './page/assets/running/AssetsBusinessRunningContainer';
import AssetsUpdateContainer from './page/assets/update/AssetsUpdateContainer';
import AssetRoomsEditorContainer from './page/assets/room/AssetRoomsEditorContainer';
import EventsContainer from './page/events/EventsContainer';
import EventEditorContainer from './page/events/add/EventEditorContainer';
import EventUpdateContainer from './page/events/update/EventUpdateContainer';
import MembershipContainer from './page/memberships/MembershipsContainer';
import MembershipsEditorContainer from './page/memberships/add/MembershipEditorContainer';
import MembershipUpdateContainer from './page/memberships/update/MembershipUpdateContainer';
import UsersContainer from './page/users/UsersContainer';
import PaymentContainer from './page/payment/PaymentContainer';
import ReserveContainer from './page/reserve/ReserveContainer';
import EventQrCheckContainer from './page/check/EventQrCheckContainer';
import NFTMembershipContainer from './page/nftmembership/NFTMembershipContainer';
import SystemSettingsContainer from './page/syssetting/SystemSettingsContainer';

export function getRouters(level?: string) {
    if (level === 'admin') {
        const routes: RouteObject[] = [
            {
                path: '/',
                element: <HeaderLayout showMenu />,
                children: [
                    {
                        path: '/',
                        element: <MainContainer />
                    },
                    {
                        path: '/login',
                        element: <LoginContainer />
                    },
                    {
                        path: '/signup',
                        element: <SignUpContainer />
                    },

                    {
                        path: '/profile',
                        element: <ProfileContainer />
                    },
                    {
                        path: '/asset',
                        element: <AssetsContainer />
                    },
                    {
                        path: '/event',
                        element: <EventsContainer />
                    },
                    {
                        path: '/event/add',
                        element: <EventEditorContainer />
                    },
                    {
                        path: '/event/update',
                        element: <EventUpdateContainer />
                    },
                    {
                        path: '/asset/detail',
                        element: <AssetDetailContainer />
                    },
                    {
                        path: '/asset/wday',
                        element: <AssetBussinessRunningContainer />
                    },
                    {
                        path: '/asset/update',
                        element: <AssetsUpdateContainer />
                    },
                    {
                        path: '/asset/add',
                        element: <AssetAddContainer />
                    },
                    {
                        path: '/asset/room',
                        element: <AssetRoomsEditorContainer />
                    },
                    {
                        path: '/asset/room/update',
                        element: <AssetRoomsEditorContainer mode="update" />
                    },
                    {
                        path: '/archives',
                        element: <ArchivesContainer />
                    },
                    {
                        path: '/memberships',
                        element: <MembershipContainer />
                    },
                    {
                        path: '/memberships/add',
                        element: <MembershipsEditorContainer />
                    },
                    {
                        path: '/memberships/update',
                        element: <MembershipUpdateContainer />
                    },
                    {
                        path: '/archives/add',
                        element: <ArchivesEditorContainer />
                    },
                    {
                        path: '/users',
                        element: <UsersContainer />
                    },
                    {
                        path: '/sighnup',
                        element: <SignUpContainer />
                    },
                    {
                        path: '/sysset',
                        element: <SystemSettingsContainer />
                    },
                    {
                        path: '/payments',
                        element: <PaymentContainer />
                    },
                    {
                        path: '/reserves',
                        element: <ReserveContainer />
                    },
                    {
                        path: '/configs',
                        element: <ReserveContainer />
                    },
                    {
                        path: '/qrcheck',
                        element: <EventQrCheckContainer />
                    },
                    {
                        path: '/nftmembership',
                        element: <NFTMembershipContainer />
                    }
                ]
            }
        ];
        return routes;
    } else if (level === 'manager') {
        const routes: RouteObject[] = [
            {
                path: '/',
                element: <HeaderLayout showMenu />,
                children: [
                    {
                        path: '/',
                        element: <MainContainer />
                    },
                    {
                        path: '/login',
                        element: <LoginContainer />
                    },
                    {
                        path: '/reserves',
                        element: <ReserveContainer />
                    },
                    {
                        path: '/profile',
                        element: <ProfileContainer />
                    },
                    {
                        path: '/qrcheck',
                        element: <EventQrCheckContainer />
                    }
                ]
            }
        ];
        return routes;
    } else {
        const routes: RouteObject[] = [
            {
                path: '/',
                element: <HeaderLayout showMenu />,
                children: [
                    {
                        path: '/',
                        element: <MainContainer />
                    },
                    {
                        path: '/login',
                        element: <LoginContainer />
                    }
                ]
            }
        ];
        return routes;
    }
}
