import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { APINFT } from 'src/apis/nft';
import { APIError } from 'src/apis/error';
import { HttpStatusCode } from 'axios';
import { NftItems } from 'src/apis/type';

export const useUserNFTsQuery = () => {
    return useQuery({
        queryKey: ['@nft/user/mutation'],
        queryFn: async () => {
            try {
                const result = await APINFT.getUserNFTs();
                if (result.status === HttpStatusCode.Ok) {
                    return result.data as NftItems;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        },
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchInterval: 1000 * 60 * 5,
    });
}

export const useMintGenesisMutateQuery = () => {
    return useMutation({
        mutationKey: ['@membership/genesis/mutation.mint'],
        mutationFn: async ({ wallet, type }: { wallet: string, type: string }) => {
            try {
                console.log('wallet', wallet)
                const result = await APINFT.mintGenesisNFTs(wallet, type);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};