import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { APIPayment } from 'src/apis/payment';
import { APIError } from 'src/apis/error';
import { HttpStatusCode } from 'axios';
import { Payments } from 'src/apis/type';

// startDate and endDate is format of 'YYYY-MM-DD'
export const usePaymentMutationQuery = (
    search?: string,
    startDate?: Date,
    endDate?: Date,
    page?: number,
    limit?: number
) => {
    return useQuery({
        queryKey: ['@payment/mutation.list', search, startDate, endDate, page, limit],
        queryFn: async () => {
            try {
                //date to yyyy-mm-dd
                let startDateText = '';
                let endDateText = '';
                if (endDate) {
                    endDateText = endDate.toISOString().split('T')[0];
                }
                if (startDate) {
                    startDateText = startDate.toISOString().split('T')[0];
                }
                const result = await APIPayment.get(search, startDateText, endDateText, page, limit);
                if (result.status === HttpStatusCode.Ok) {
                    return result.data as Payments;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true
    });
};
