import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, IconButton, Box, MenuItem, Menu, Chip } from '@mui/material';
import { useEventsQuery } from 'src/apis/hook/event';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    useDeleteEventMutationQuery,
    useUpdateEventAvailableMutationQuery,
    useUpdateEventClosedMutationQuery
} from 'src/apis/hook/event';
import { useSimpleAlert } from 'src/store/ui';
import { convertMinutesToTimeFromatString } from 'src/apis/ui';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Event } from 'src/apis/type';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import PauseIcon from '@mui/icons-material/Pause';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQrCodeCheckUI } from 'src/store/ui';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useEventListStore } from 'src/store/event';

export const EventsList: FC = () => {
    const { page, limit, setPage, setSelectedEvent, setLimit } = useEventListStore();
    const { data: events, refetch: refetchEvents } = useEventsQuery(page, limit);
    const { mutateAsync: availableEvent } = useUpdateEventAvailableMutationQuery();
    const { mutateAsync: closedEvent } = useUpdateEventClosedMutationQuery();
    const { mutateAsync: deleteEvent } = useDeleteEventMutationQuery();
    const { alert: simpleAlert } = useSimpleAlert();
    const matches = useMediaQuery('(min-width:700px)');
    const { setEvent } = useQrCodeCheckUI();
    const navigator = useNavigate();
    const { t } = useTranslation();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 2 },
        {
            field: 'createdAt',
            headerName: '생성일',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => {
                return new Date(params.row.createdAt).toLocaleDateString();
            }
        },
        { field: 'name', headerName: t('names:name'), flex: 3 },
        { field: 'type', headerName: t('names:typeName'), flex: 2 },
        { field: 'desc', headerName: t('names:description'), flex: 3 },
        {
            field: 'date',
            headerName: t('names:date'),
            flex: 3,
            renderCell: (params) => {
                return new Date(params.row.date).toLocaleDateString();
            }
        },
        {
            field: 'startTime',
            headerName: t('names:startTime'),
            flex: 2,
            renderCell: (params) => {
                return convertMinutesToTimeFromatString(params.row.startTime);
            }
        },
        {
            field: 'statuss',
            headerName: t('names:status'),
            flex: 2,
            renderCell: (params) => {
                return (
                    <Box width="100%" p={1} display="flex" flexDirection="column" alignItems="center">
                        <Box>
                            <Chip
                                label={params.row.isAvailable ? t('names:active') : t('names:inactived')}
                                color={params.row.isAvailable ? 'primary' : 'error'}
                            />
                        </Box>
                        <Box>
                            <Chip
                                label={params.row.isClosed ? t('names:closed') : t('names:opened')}
                                color={params.row.isClosed ? 'error' : 'primary'}
                                sx={{ ml: 0.5 }}
                            />
                        </Box>
                    </Box>
                );
            }
        },
        {
            field: 'control',
            headerName: '',
            flex: 3,
            renderCell: (params) => {
                const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
                const open = Boolean(anchorEl);

                const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget);
                };

                const handleClose = () => {
                    setAnchorEl(null);
                };
                return (
                    <>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setSelectedEvent(params.row as Event);
                                    navigator('/event/update');
                                }}
                            >
                                <Button startIcon={<AutoFixHighIcon />}>{t('names:doUpdate')}</Button>
                            </MenuItem>
                            <MenuItem
                                onClick={async () => {
                                    const confirmDelete = confirm('정말 삭제하시겠습니까?');
                                    if (!confirmDelete) return;
                                    const result = await deleteEvent(params.row._id);
                                    if (result) {
                                        simpleAlert(t('names:notification'), t('names:deleteSuccess'), 'info');
                                        refetchEvents();
                                    } else {
                                        simpleAlert(t('names:error'), t('names:deleteFail'), 'error');
                                    }
                                }}
                            >
                                <Button startIcon={<DeleteIcon />}>{t('names:delete')}</Button>
                            </MenuItem>
                            <MenuItem
                                onClick={async () => {
                                    const qustion = `${
                                        params.row.isAvailable ? t('names:inactive') : t('names:active')
                                    } ${t('names:chageActive')}`;
                                    if (confirm(qustion)) {
                                        await availableEvent({
                                            id: params.row._id,
                                            isAvailable: !params.row.isAvailable
                                        });
                                        refetchEvents();
                                    }
                                    handleClose();
                                }}
                            >
                                <Button startIcon={<AccessibilityIcon />}>
                                    {params.row.isAvailable ? t('names:inactive') : t('names:active')}
                                </Button>
                            </MenuItem>
                            <MenuItem
                                onClick={async () => {
                                    const qustion = `${params.row.isClosed ? t('names:close') : t('names:open')} ${t(
                                        'names:chageActive'
                                    )}`;
                                    if (confirm(qustion)) {
                                        await closedEvent({ id: params.row._id, isClosed: !params.row.isClosed });
                                        refetchEvents();
                                    }
                                    handleClose();
                                }}
                            >
                                <Button startIcon={<PauseIcon />}>
                                    {params.row.isClosed ? t('names:opened') : t('names:closed')}
                                </Button>
                            </MenuItem>
                            <MenuItem
                                onClick={async () => {
                                    setEvent({
                                        event: params.row,
                                        type: 'event'
                                    });
                                    navigator(`/qrcheck?type=reserve&eventId=${params.row._id}`);
                                }}
                            >
                                <Button startIcon={<QrCodeScannerIcon />}>{t('names:qrCodeCheck')}</Button>
                            </MenuItem>
                        </Menu>
                        <IconButton onClick={handleClick} sx={{ p: 0 }}>
                            <MoreVertIcon />
                        </IconButton>
                    </>
                );
            }
        }
    ];

    const [columnsAdapted, setColumnsAdapted] = useState<GridColDef[]>([]);

    useEffect(() => {
        if (!matches) {
            let newArray = columns.slice(0, 4).concat(columns.slice(4 + 1));
            newArray = newArray.slice(0, 0).concat(newArray.slice(1 + 1));

            newArray = newArray.slice(0, 3).concat(newArray.slice(3 + 1));
            setColumnsAdapted(newArray);
        } else {
            setColumnsAdapted(columns);
        }
    }, [matches]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {events && events.length > 0 && (
                <DataGrid
                    getRowHeight={() => 'auto'}
                    rows={events}
                    disableColumnMenu
                    columns={columnsAdapted}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 }
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                brokerId: false,
                                status: false
                            }
                        }
                    }}
                    disableRowSelectionOnClick
                    onPaginationModelChange={(pagination) => {
                        setLimit(pagination.pageSize);
                        setPage(pagination.page);
                    }}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: t('names:rowsPerPage')
                        }
                    }}
                    pageSizeOptions={[5, 10]}
                />
            )}
        </div>
    );
};
