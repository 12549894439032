import React from 'react';
import { useState, useCallback } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import { colors } from 'src/theme/colors';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLoginMutationQuery, useAuthenticated } from 'src/apis/hook/user';
import { useSimpleAlert } from 'src/store/ui';
import { APIError } from 'src/apis/error';
import { HttpStatus } from '@haechi-labs/face-types';

const LoginContainer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mutateAsync: loginMutation } = useLoginMutationQuery();
    const { refetch: authenticatedRefetch } = useAuthenticated();
    const { alert: simpleAlert } = useSimpleAlert();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submit = useCallback(async () => {
        try {
            if (email && password && email.length > 0 && password.length > 0) {
                const data = await loginMutation({ email, password });
                if (data) {
                    authenticatedRefetch();
                    simpleAlert(t('names:notification'), t('names:successLogin'), 'info');
                    navigate('/');
                }
            } else {
                simpleAlert(t('names:error'), t('names:missingLoginEssential'), 'error');
            }
        } catch (e) {
            const error = e as APIError;
            if (error.status === 406) {
                simpleAlert(t('names:error'), t('names:wrongPassword'), 'error');
            } else if (error.status === 404) {
                simpleAlert(t('names:error'), t('names:notFoundUserInfoError'), 'error');
            } else {
                simpleAlert(t('names:error'), t('names:unknownError'), 'error');
            }
        }
    }, [email, password]);
    const updateValue = async (value: string, mode: string) => {
        if (mode === 'email') {
            setEmail(value);
        } else if (mode === 'password') {
            setPassword(value);
        }
    };
    return (
        <>
            <Box width="100%" height="100%" justifyContent={'center'} display="flex">
                <Box
                    sx={{ maxWidth: '640px' }}
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    mt="20px"
                    alignItems="center"
                >
                    <Box mb={1}>
                        <Typography fontSize="24px" fontWeight={700} color="black">
                            {t('names:login')}
                        </Typography>
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <TextField
                            id="outlined-basic"
                            label={t('names:email')}
                            variant="outlined"
                            size="small"
                            value={email}
                            sx={{ width: '350px' }}
                            onChange={(e) => updateValue(e.target.value, 'email')}
                        />
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <TextField
                            id="outlined-basic"
                            label={t('names:password')}
                            type="password"
                            variant="outlined"
                            size="small"
                            sx={{ width: '350px' }}
                            value={password}
                            onChange={(e) => updateValue(e.target.value, 'password')}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    submit();
                                }
                            }}
                        />
                    </Box>
                    <Box mt={1}>
                        <Box width="100%" display="flex" justifyContent="center">
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    width: '95%',
                                    borderColor: colors.main.gray02,
                                    borderRadius: '8px',
                                    '&:focus': {
                                        borderColor: colors.main.gray01
                                    }
                                }}
                                onClick={submit}
                                variant="contained"
                            >
                                <Box width={'180px'}>
                                    <Typography fontSize="16px" color="white">
                                        {t('names:login')}
                                    </Typography>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default LoginContainer;
