import * as React from 'react';
import { FC, useRef, useState, useEffect } from 'react';
import { Box, Button, Typography, FormControl, InputLabel, Grid, Select, MenuItem, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSimpleAlert } from 'src/store/ui';
import dayjs from 'dayjs';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AssetBusinessHours } from 'src/apis/type';
import { BusinessRunningDialog } from './businessrunning/BusinessRunningDlg';
import { useAssetsUI } from 'src/store/ui';
import { convertToDayOfWeek } from 'src/apis/ui';
import {
    useInsertAssetRunningHoursMutationQuery,
    useAssetsRunningHoursQuery,
    useAssetsRunningHoursCountQuery,
    useInsertAssetRunningHourMutationQuery
} from 'src/apis/hook/asset';
import { HeaderAddActionButton } from 'src/page/components/HeaderAddActionButton';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
export interface AddAssetDayRunningEditorProps {
    onChange?: (runnings: AssetBusinessHours) => void;
    onCloseAddDialog?: () => void;
    openAddDialog?: boolean;
}
export const AddAssetDayRunningEditor: FC<AddAssetDayRunningEditorProps> = ({
    onChange,
    openAddDialog,
    onCloseAddDialog
}) => {
    const { alert: simpleAlert } = useSimpleAlert();
    const { selectedAsset } = useAssetsUI();
    const { mutateAsync: rhsInsertAsync } = useInsertAssetRunningHoursMutationQuery();
    const { mutateAsync: rhInsertAsync } = useInsertAssetRunningHourMutationQuery();

    const [assetDayRunnings, setAssetDayRunnings] = useState<AssetBusinessHours>([]);

    const [limit, setLimit] = useState(5);
    const [page, setPage] = useState(0);
    //original running hours data
    const { data: assetRunningHours, refetch: assetRunningHoursRefetch } = useAssetsRunningHoursQuery(
        selectedAsset?.id
    );
    const { data: assetRunningHoursCount, refetch: assetRunningHoursCountRefetch } = useAssetsRunningHoursCountQuery(
        selectedAsset?.id
    );
    const { t } = useTranslation();
    const wdayColumns: GridColDef[] = [
        {
            field: 'wday',
            flex: 1,
            headerName: t('names:weekDay'),
            renderCell: (params) => {
                return convertToDayOfWeek(params.value);
            }
        },

        {
            field: 'isRunning',
            headerName: t('names:description'),
            flex: 1,
            renderCell: (params) => {
                return params.value ? t('names:running') : t('names:dayOff');
            }
        },
        {
            field: 'startTime',
            headerName: t('names:startTime'),
            flex: 1,
            renderCell: (params) => {
                return (
                    `${Math.floor(params.value / 60)}`.padStart(2, '0') + ':' + `${params.value % 60}`.padStart(2, '0')
                );
            }
        },
        {
            field: 'endTime',
            headerName: t('names:endTime'),
            flex: 1,
            renderCell: (params) => {
                return (
                    `${Math.floor(params.value / 60)}`.padStart(2, '0') + ':' + `${params.value % 60}`.padStart(2, '0')
                );
            }
        }
    ];

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('names:name'), minWidth: 50 },
        {
            field: 'date',
            flex: 2,
            headerName: t('names:date'),
            renderCell: (params) => {
                return dayjs(params.value).format('YYYY-MM-DD');
            }
        },
        { field: 'desc', headerName: t('names:description'), width: 70 },
        {
            field: 'startTime',
            headerName: t('names:startTime'),
            flex: 2,
            renderCell: (params) => {
                return `${params.value / 60}`.padStart(2, '0') + ':' + `${params.value % 60}`.padStart(2, '0');
            }
        },
        {
            field: 'endTime',
            headerName: t('names:endTime'),
            flex: 2,
            renderCell: (params) => {
                return `${params.value / 60}`.padStart(2, '0') + ':' + `${params.value % 60}`.padStart(2, '0');
            }
        },
        {
            field: 'isRunning',
            headerName: '형태',
            flex: 1,
            renderCell: (params) => {
                return params.value ? t('names:running') : t('names:dayOff');
            }
        },
        {
            field: 'delete',
            headerName: '',
            flex: 2,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            sx={{ color: 'red' }}
                            onClick={async () => {
                                const confirmDelete = confirm('정말 삭제하시겠습니까?');
                                if (confirmDelete) {
                                    const values = assetDayRunnings.filter((running) => {
                                        return running.id !== params.row.id;
                                    });
                                    setAssetDayRunnings(values);
                                }
                            }}
                        >
                            {t('names:delete')}
                        </Button>
                    </Box>
                );
            }
        }
    ];

    const onRegister = async () => {
        if (selectedAsset && selectedAsset.id) {
            const result = await rhsInsertAsync({
                id: selectedAsset._id ? selectedAsset._id : '',
                datas: assetDayRunnings
            });
            if (result) {
                simpleAlert(t('names:notification'), t('names:success'), 'info');
            } else {
                simpleAlert(t('names:error'), t('names:unKnownError'), 'error');
            }
        }
    };

    const [openRunningDialog, setOpenRunningDialog] = useState(false);

    return (
        <>
            <BusinessRunningDialog
                open={openRunningDialog}
                onOk={async (value) => {
                    const isDateAlreadyInList = assetDayRunnings.some((running) => {
                        return dayjs(running.date).isSame(value.date, 'day');
                    });

                    if (isDateAlreadyInList) {
                        simpleAlert(t('names:notification'), t('names:alreadyExistDate'), 'error');
                    } else {
                        if (selectedAsset && selectedAsset.id) {
                            const result = await rhInsertAsync({ id: selectedAsset.id, data: value });
                            if (result) {
                                simpleAlert(t('names:notification'), t('names:successAdd'), 'info');
                                assetRunningHoursRefetch();
                                assetRunningHoursCountRefetch();
                            } else {
                                simpleAlert(t('names:error'), t('names:unKnownError'), 'error');
                            }
                        }
                    }
                    setOpenRunningDialog(false);
                    setOpenRunningDialog(false);
                }}
                onClose={() => {
                    setOpenRunningDialog(false);
                }}
            />
            <Box width="100%" alignItems="center" display="flex" flexDirection="column" p={1}>
                {selectedAsset && (
                    <Box width="100%" p={1}>
                        <Box width="100%" display="flex" alignItems="center">
                            <Typography fontSize="18px" fontWeight={900}>
                                {t('names:runningTimeByWeekDay')}
                            </Typography>
                            <HeaderAddActionButton
                                mode="iconButton"
                                icon={<BuildCircleIcon />}
                                routerPath="/asset/update"
                            />
                        </Box>
                        <Box>
                            {selectedAsset.runningDays && selectedAsset.runningDays.length > 0 && (
                                <DataGrid
                                    rows={selectedAsset.runningDays.map((running, index) => {
                                        return { ...running, id: index };
                                    })}
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                id: false,
                                                brokerId: false,
                                                status: false
                                            }
                                        }
                                    }}
                                    columns={wdayColumns}
                                    hideFooterPagination
                                />
                            )}
                        </Box>
                    </Box>
                )}
                <Box width="100%" height="20px" mt={5}>
                    <Divider />
                </Box>
                <Box width="100%" p={1}>
                    <Box width="100%" display="flex" alignItems="center">
                        <Typography fontSize="18px" fontWeight={900}>
                            {t('names:runningTimeByDate')}
                        </Typography>
                        <HeaderAddActionButton
                            mode="iconButton"
                            onClick={() => {
                                setOpenRunningDialog(true);
                            }}
                        />
                    </Box>
                    <Box width="100%" height="500px">
                        {assetRunningHours && assetRunningHours.length > 0 && (
                            <DataGrid
                                rows={assetRunningHours.map((running, index) => {
                                    return { ...running, id: index };
                                })}
                                columns={columns}
                                slotProps={{
                                    pagination: {
                                        labelRowsPerPage: t('names:rowsPerPage')
                                    }
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 }
                                    }
                                }}
                                pageSizeOptions={[5, 10]}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};
